<div class="page-container">
	<header class="page-title">
		<h3>Cambia tu contraseña</h3>
	</header>
	<div class="flex-column">
		<div class="form-section">
			<form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
				<div
					class="input-group"
					[class.error-found]="(resetPasswordForm.invalid && submitted) || resetPasswordErrors.length > 0"
				>
					<label for="password">Contraseña</label>
					<div class="password-visibility-toggle">
						<input
							#password
							type="password"
							name="password"
							formControlName="password"
							placeholder="Al menos 8 caracteres"
						/>
						<i
							class="fas fa-eye"
							(click)="onToggleVisibility('password')"
							*ngIf="!pwdVisibility.password"
						></i>
						<i
							class="fas fa-eye-slash"
							(click)="onToggleVisibility('password')"
							*ngIf="pwdVisibility.password"
						></i>
					</div>
					<div
						class="errors-container"
						*ngIf="resetPasswordForm.controls.password?.errors || resetPasswordErrors.length > 0"
					>
						<span *ngIf="resetPasswordForm.controls.password?.errors?.required && submitted"
							>Este campo es obligatorio</span
						>
						<span *ngIf="resetPasswordForm.controls.password?.errors?.minlength && submitted"
							>Por favor, introduce al menos 8 caracteres</span
						>
					</div>
				</div>
				<div
					class="input-group"
					[class.error-found]="(resetPasswordForm.invalid && submitted) || resetPasswordErrors.length > 0"
				>
					<label for="passwordConfirmation">Confirmar contraseña</label>
					<div class="password-visibility-toggle">
						<input
							#confirmPassword
							type="password"
							name="passwordConfirmation"
							formControlName="passwordConfirmation"
							placeholder="Escribe aquí tu contraseña"
						/>
						<i
							class="fas fa-eye"
							(click)="onToggleVisibility('confirmPassword')"
							*ngIf="!pwdVisibility.confirmPassword"
						></i>
						<i
							class="fas fa-eye-slash"
							(click)="onToggleVisibility('confirmPassword')"
							*ngIf="pwdVisibility.confirmPassword"
						></i>
					</div>
					<div
						class="errors-container"
						*ngIf="resetPasswordForm.controls.passwordConfirmation?.errors || resetPasswordErrors.length > 0"
					>
						<span *ngIf="resetPasswordForm.controls.passwordConfirmation?.errors?.required && submitted"
							>Este campo es obligatorio</span
						>
						<span
							*ngIf="
								!resetPasswordForm.controls.password?.errors?.matchPassword &&
								!resetPasswordForm.controls.passwordConfirmation?.errors?.required &&
								submitted
							"
							>Las contraseñas no coinciden</span
						>
					</div>
				</div>
				<button class="box-shadow-md large-accent-btn" type="submit">Reiniciar contraseña</button>
			</form>
			<app-alert
				[type]="alertSuccess.type"
				[message]="alertSuccess.message"
				[showAlert]="alertSuccess.show"
				(closeAlert)="alertSuccess.close()"
			></app-alert>
			<app-alert
				[type]="alertError.type"
				[message]="alertError.message"
				[showAlert]="alertError.show"
				(closeAlert)="alertError.close()"
			></app-alert>
		</div>
	</div>
</div>
