import { Component, OnInit } from '@angular/core';
import { AlertEnum } from 'src/app/schemes/enums/alert.enum';
import { AuthService } from 'src/app/services/auth.service';
import { UtilsService } from './../../services/utils.service';

@Component({
	selector: 'app-unconfirmed-account',
	templateUrl: './unconfirmed-account.component.html',
	styleUrls: ['./unconfirmed-account.component.scss'],
})
export class UnconfirmedAccountEmailComponent implements OnInit {
	userEmail = localStorage.getItem('unconfirmedUserEmail') || '';
	encodedEmail!: string;

	alertSend = {
		message: ['Hemos reenviado el correo de confirmación a ' + this.userEmail],
		type: AlertEnum['email'],
		show: false,
		close: () => {
			this.alertSend.show = false;
		},
	};
	alertError = {
		message: ['Se ha producido un error...'],
		type: AlertEnum['error'],
		show: false,
		close: () => {
			this.alertError.show = false;
		},
	};

	constructor(private auth: AuthService, private utils: UtilsService) {}

	ngOnInit(): void {
		if (this.userEmail) {
			this.encodedEmail = this.utils.encodeEmail(this.userEmail);
		}
	}

	public async onResendConfirmation(): Promise<void> {
		await this.auth
			.resendConfirmationEmail(this.userEmail)
			.then(() => {
				this.alertSend.show = true;
			})
			.catch(() => {
				this.alertError.show = true;
			});
	}
}
