import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoursePricePipeModule } from 'src/app/shared/pipes/course-price/course-price.module';
import { ImageModule } from '../image/image.module';
import { CourseElementComponent } from './course-element.component';

@NgModule({
	imports: [CoursePricePipeModule, ImageModule, CommonModule, FormsModule, RouterModule.forChild([])],
	exports: [CourseElementComponent],
	declarations: [CourseElementComponent],
})
export class CourseElementModule {}
