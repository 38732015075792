import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SubscriptionElementComponent } from './subscription-element.component';

@NgModule({
	imports: [RouterModule.forChild([]), CommonModule],
	exports: [SubscriptionElementComponent],
	declarations: [SubscriptionElementComponent],
	providers: [],
})
export class SubscritionElementModule {}
