import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { JWTService } from './jwt.service';

@Injectable({
	providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService, private jwt: JWTService) {}
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let token = localStorage.getItem('auth_token');

		if (!token) {
			return next.handle(req);
		}

		if (this.jwt.isThisTokenExpired(token)) {
			// Token must be refreshed
			try {
				this.authService.refreshToken(token).then(newToken => {
					localStorage.setItem('auth_token', newToken);
				});
			} catch (error) {
				console.log(error);
			}
		}

		const headers = req.clone({
			headers: req.headers.set('Authorization', `Bearer ${token}`),
		});
		return next.handle(headers);
	}
}
