<div class="course-element">
	<div class="content-image" [routerLink]="['/cursos', course.slug]">
		<span class="last-viewed box-shadow-md" *ngIf="showRecentlyViewedLabel"> Último visualizado</span>
		<app-image
			[srcImage]="course.cover_image ? course.cover_image.url : environment.placeholderImage"
		></app-image>
		<div class="bottom-info">
			<div class="duration">
				<img src="assets/images/icons/clock-white.svg" alt="clock-icon" />
				<span>{{ course.duration }}</span>
			</div>
			<div class="difficulty">
				<span>{{ course.difficulty }}</span>
			</div>
		</div>
	</div>
	<div class="content-text">
		<div class="course-title">
			<span [routerLink]="['/cursos', course.slug]">{{ course.title }}</span>
			<img src="assets/images/icons/lock.svg" alt="lock-icon" *ngIf="!course.purchased" />
		</div>
		<span class="course-author">{{ author ? author.name + ' ' + author.surname : 'Dharma en Calma' }}</span>
		<p class="course-description">{{ course.description }}</p>
		<div class="course-price-flex-row" *ngIf="!course.purchased">
			<span class="course-price" [routerLink]="['/compra/curso/', course.slug]">
				<p>{{ course | coursePricePipe }}€</p>
			</span>
			<span class="subscriptor-price">{{ course.subscriber_price }}€ para suscriptores</span>
		</div>
		<!-- MOSTRAR SOLO CUANDO ESTÉ COMPRADO -->
		<span class="bought" *ngIf="course.purchased && showPurchaseLabel"> Adquirido </span>
	</div>
</div>
