import { Injectable } from '@angular/core';
import { ClassModel } from '../schemes/models/class.model';
import { HttpService } from '../services/http.service';
import { BaseProvider } from './base.provider';
@Injectable({
	providedIn: 'root',
})
export class ClassProvider extends BaseProvider<ClassModel> {
	constructor(http: HttpService) {
		super('classes', http);
	}

	async addVisit(class_id: number, video_id: number): Promise<any> {
		try {
			return this.http.put(`${this.collection}/addvisit`, { class_id, video_id });
		} catch (error) {
			throw error;
		}
	}
}
