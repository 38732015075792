<div class="page-container">
	<header class="page-title">
		<h3>Configura tu cuenta</h3>
		<p class="subtitle">Ayúdanos a ofrecerte la mejor experiencia dentro de Dharma en Calma</p>
	</header>
	<div class="flex-column">
		<div class="form-section">
			<form [formGroup]="setupAccountForm" (ngSubmit)="onSubmit()">
				<div
					class="input-group"
					[class.error-found]="
						setupAccountForm.invalid && submitted && setupAccountForm.controls.name?.errors
					"
				>
					<label for="name">Nombre</label>
					<input type="text" name="name" formControlName="name" />
					<div class="errors-container" *ngIf="setupAccountForm.controls.name?.errors">
						<span *ngIf="setupAccountForm.controls.name?.errors?.required && submitted"
							>Este campo es obligatorio</span
						>
					</div>
				</div>
				<div
					class="input-group"
					[class.error-found]="
						setupAccountForm.invalid && submitted && setupAccountForm.controls.surname?.errors
					"
				>
					<label for="name">Apellidos</label>
					<input type="text" name="name" formControlName="surname" />
					<div class="errors-container" *ngIf="setupAccountForm.controls.surname?.errors">
						<span *ngIf="setupAccountForm.controls.surname?.errors?.required && submitted"
							>Este campo es obligatorio</span
						>
					</div>
				</div>
				<div
					class="input-group select-input"
					[class.error-found]="
						setupAccountForm.invalid && submitted && setupAccountForm.controls.reach?.errors
					"
				>
					<label for="name">¿Cómo nos conociste?</label>
					<div class="select-wrapper">
						<select name="reach" id="reach" formControlName="reach">
							<option value="" selected disabled hidden>Selecciona una opción</option>
							<option value="instagram">Instagram</option>
							<option value="facebook">Facebook</option>
							<option value="youtube">YouTube</option>
							<option value="web">Web</option>
							<option value="recomendacion">Por recomendación personal</option>
							<option value="otros">Otros</option>
						</select>
					</div>
					<div class="errors-container" *ngIf="setupAccountForm.controls.reach?.errors">
						<span *ngIf="setupAccountForm.controls.reach?.errors?.required && submitted"
							>Este campo es obligatorio</span
						>
					</div>
				</div>

				<div class="checkboxes-container">
					<div class="checkbox-label-row">
						<input
							type="checkbox"
							id="checkbox-newsletter"
							(change)="onCheck($event)"
							[checked]="newsletterAccepted"
						/>
						<label for="checkbox-newsletter"
							>Deseo recibir noticias e información relevante por e-mail.</label
						>
					</div>
				</div>
				<div class="form-buttons">
					<button class="submit box-shadow-md" type="submit">Guardar</button>
				</div>
			</form>
		</div>
	</div>
</div>
<app-alert
	[type]="alertSuccess.type"
	[message]="alertSuccess.message"
	[showAlert]="alertSuccess.show"
	(closeAlert)="alertSuccess.close()"
></app-alert>
<app-alert
	[type]="alertError.type"
	[message]="alertError.message"
	[showAlert]="alertError.show"
	(closeAlert)="alertError.close()"
></app-alert>
