<div class="page-container" *ngIf="(pageLoaded | async) && gift">
	<header class="page-title">
		<h3>Tenemos una sorpresa para ti</h3>
	</header>
	<div class="content">
		<div class="description">
			<p>¡Hola {{ gift.receiver_name }}!</p>
			<p>
				{{ gift.sender_name }} te ha regalado una suscripción de
				<span class="emphasis">{{ giftedPlanInterval }}</span> para disfrutar sin límites del contenido de
				Dharma en Calma.
			</p>
		</div>
		<ng-container *ngIf="gift.message_info; else giftImage">
			<div class="dedication">
				<div class="text-overflow">
					<span class="from">Mensaje de {{ gift.sender_name }}</span>
					<p class="content">{{ gift.message_info }}</p>
				</div>
			</div>
		</ng-container>
		<ng-template #giftImage>
			<div class="gift-image">
				<img src="assets/images/illustrations/gift.svg" alt="gift-illustration" />
			</div>
		</ng-template>
		<div class="activate-container">
			<span *ngIf="!currentUser"
				>Para activar este regalo, es necesario disponer de una cuenta en Dharma en Calma y haber iniciado
				sesión.</span
			>
			<span *ngIf="currentUser && cannotActivateGift"
				>No puedes activar este regalo si tienes una suscripción activa.</span
			>
			<button class="cta-primary" (click)="onActivateGift()" [disabled]="currentUser && cannotActivateGift">
				{{ currentUser ? 'Activar regalo' : 'Crear cuenta' }}
			</button>
		</div>
	</div>
</div>

<app-alert
	[type]="alertSuccess.type"
	[message]="alertSuccess.message"
	[heading]="alertSuccess.heading"
	[showAlert]="alertSuccess.show"
	(closeAlert)="alertSuccess.close()"
></app-alert>
<app-alert
	[type]="alertError.type"
	[message]="alertError.message"
	[showAlert]="alertError.show"
	(closeAlert)="alertError.close()"
></app-alert>
