import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Card, Token } from '@stripe/stripe-js';
import { map, take } from 'rxjs/operators';
import {
	StripePaymentMethod,
	StripeUserCard,
	StripeUserCardsModel,
} from '../schemes/models/stripe-user-cards.model';
import { SubscriptionModel } from '../schemes/models/subscription.model';
import { UserModel } from '../schemes/models/user.model';
import { environment } from './../../environments/environment';
import { GlobalAlertStatusModel } from './../schemes/models/global-alert-status.model';

@Injectable({
	providedIn: 'root',
})
export class SubscriptionService {
	constructor(private http: HttpClient) {}

	//Stripe
	async getUserSubscriptionDetails(): Promise<SubscriptionModel> {
		return this.http
			.get<SubscriptionModel>(environment.apiUrl + 'stripe/user/subscription')
			.pipe(take(1))
			.toPromise();
	}

	retrieveStripeCardToken(tokenId: string) {
		return this.http
			.get<Token>(environment.apiUrl + 'stripe/user/retrieve-card-token/' + tokenId)
			.pipe(take(1))
			.toPromise();
	}

	async cancelSubscriptionAtPeriodEnd(): Promise<any> {
		try {
			//? Tiene sentido usar un método PUT sin body?
			const response = await this.http
				.put(environment.apiUrl + 'stripe/user/subscription/cancelatend', null)
				.pipe(take(1))
				.toPromise();
			return response;
		} catch (error) {
			// console.log(error);
			return null;
		}
	}
	async reactivateSubscription(): Promise<any> {
		try {
			//? Tiene sentido usar un método PUT sin body?
			const response = await this.http
				.put(environment.apiUrl + 'stripe/user/subscription/reactivate', null)
				.pipe(take(1))
				.toPromise();
			return response;
		} catch (error) {
			// console.log(error);
			return null;
		}
	}

	//TODO CHECK CASES WHEN PAYMENT METHOD IS NOT LONGER VALID
	async addSubscription(data: {
		priceId: string;
		defaultPaymentMethod?: string;
		defaultSource?: string;
		trialPeriodDays?: number;
	}): Promise<SubscriptionModel | UserModel> {
		let body: {
			priceId: string;
			defaultPaymentMethod?: string;
			defaultSource?: string;
			trialPeriodDays?: number;
		} = {
			priceId: data.priceId,
		};
		if (data.trialPeriodDays) body.trialPeriodDays = data.trialPeriodDays;

		data.defaultPaymentMethod
			? (body.defaultPaymentMethod = data.defaultPaymentMethod)
			: (body.defaultSource = data.defaultSource);

		return await this.http
			.post<SubscriptionModel | UserModel>(environment.apiUrl + 'stripe/user/subscription/', body)
			.pipe(take(1))
			.toPromise();
	}

	isValidCard(card: Card): boolean {
		const { exp_month, exp_year, cvc_check } = card;
		const exp_date = new Date(exp_year, exp_month - 1); // exp_month -1 because Date begins January with 0
		const now = new Date();

		if (exp_date.getTime() - now.getTime() > 0 && cvc_check == 'pass') {
			// console.log('Tarjeta válida');
			return true;
		} else {
			// console.log('Tarjeta no válida');
			return false;
		}
	}

	/*getClass(brand: string): string {
    switch (brand) {
      case 'visa':
        return 'Visa';

      case 'mastercard':
        return 'MasterCard';

      default:
        return 'default';
    }
  }*/

	//? ESTO SE SIGUE MANTENIENDO?
	getPriceId(method?: string) {
		switch (method) {
			case 'monthly':
				return 'price_1IgWlKAocvw1RxghRvLP9eol';

			case 'quarterly':
				return 'price_1Ihy4uAocvw1RxghvO91VQkZ';

			case 'yearly':
				return 'price_1Ihy4hAocvw1Rxgh9gffj83Y';

			default:
				return '';
		}
	}

	getStripeCustomerCards() {
		return this.http
			.get<StripeUserCardsModel>(environment.apiUrl + 'stripe/user/cards')
			.pipe(
				take(1),
				map((response: any) =>
					response.cards
						? ({
								default_card_id: response.default_card_id,
								cards: response.cards.data.map((paymentMethod: StripePaymentMethod) => {
									const response: any = {
										...paymentMethod,
										...paymentMethod.card,
										cvc_check: paymentMethod.card.checks.cvc_check,
									};
									delete response.card;
									return response;
								}) as StripeUserCard[],
						  } as StripeUserCardsModel)
						: undefined
				)
			)
			.toPromise();
	}

	async setStripeDefaultCustomerCard(cardId: string): Promise<any> {
		try {
			const body = {
				default_source: cardId,
			};
			const response = await this.http
				.post(environment.apiUrl + 'stripe/user/defaultsrc', body)
				.pipe(take(1))
				.toPromise();
			return response;
		} catch (error) {
			throw error;
		}
	}

	async deleteStripeCustomerCard(cardId: string): Promise<any> {
		try {
			const response = await this.http
				.delete(environment.apiUrl + 'stripe/user/card/' + cardId)
				.pipe(take(1))
				.toPromise();
			return response;
		} catch (error) {
			throw error;
		}
	}

	// ? HAY QUE BORRARLO 22/05
	//!BORRAR CUANDO SE DECIDA LA FORMA DE IMPLEMENTACIÓN
	async addNewCard(tokenId: string): Promise<any> {
		const body = {
			paymentMethodId: tokenId,
		};
		return await this.http
			.post(environment.apiUrl + 'stripe/user/cards', body)
			.pipe(take(1))
			.toPromise();
	}

	async getAllCustomerInvoices(): Promise<any> {
		try {
			const response = await this.http
				.get(environment.apiUrl + 'stripe/user/invoices')
				.pipe(take(1))
				.toPromise();
			return response;
		} catch (error) {
			throw error;
		}
	}

	async getScheduleByIdWithProductsInfo(scheduleId: string): Promise<any> {
		try {
			const response = await this.http
				.get(environment.apiUrl + 'stripe/user/schedule/' + scheduleId + '/products')
				.pipe(take(1))
				.toPromise();
			return response;
		} catch (error) {
			throw error;
		}
	}

	async releaseStripeUserCurrentSchedule(): Promise<any> {
		try {
			const response = await this.http
				.put(environment.apiUrl + 'stripe/user/schedule/release', null)
				.pipe(take(1))
				.toPromise();
			return response;
		} catch (error) {
			throw error;
		}
	}

	async retrySubscriptionPaymentIntentAfterFailure(): Promise<any> {
		try {
			const response = await this.http
				.put(environment.apiUrl + 'stripe/user/subscription/retry', null)
				.pipe(take(1))
				.toPromise();
			return response;
		} catch (error) {
			throw error.error.message[0].messages[0];
		}
	}

	async updateUserGlobalAlertStatus(data: { type: string; status: GlobalAlertStatusModel }): Promise<any> {
		const body = {
			type: data.type,
			solved: data.status.solved,
			user_notified: data.status.user_notified,
		};
		try {
			const response = await this.http
				.put(environment.apiUrl + 'users/me/additionalInfo/globalAlerts', body)
				.pipe(take(1))
				.toPromise();
			return response;
		} catch (error) {
			throw error;
		}
	}

	// async getPaymentIntent(id: string): Promise<any> {
	//   try {
	//     const response = await this.http.get(environment.apiUrl + 'stripe/user/paymentintent/' + id).pipe(take(1)).toPromise();
	//     console.log(response);
	//     return response;
	//   } catch (error) {
	//     throw error;
	//   }
	// }
}
