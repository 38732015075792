import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { CanActivateLoginSignupRoutesAuthGuard } from './../../guards/auth.guard';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { LoginComponent } from './login.component';
const routes: Routes = [
	{
		path: '',
		component: LoginComponent,
		canActivate: [CanActivateLoginSignupRoutesAuthGuard],
	},
	{
		path: 'forgotten-password',
		component: ForgottenPasswordComponent,
	},
];

@NgModule({
	imports: [SharedModule, RouterModule.forChild(routes), ReactiveFormsModule],
	declarations: [LoginComponent, ForgottenPasswordComponent],
})
export class LoginModule {}
