import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ImageModule } from '../image/image.module';
import { RelatedContentComponent } from './related-content.component';

@NgModule({
	imports: [ImageModule, RouterModule.forChild([]), CommonModule, FormsModule],
	exports: [RelatedContentComponent],
	declarations: [RelatedContentComponent],
	providers: [],
})
export class RelatedContentModule {}
