import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PromotionCodeModel } from '../schemes/models/promotion-code.model';
import { UserModel } from '../schemes/models/user.model';

const PROMOTION_CODE_NAME = 'promotion_code';

@Injectable({
	providedIn: 'root',
})
export class CouponService {
	constructor(private http: HttpClient) {}

	applyPromotionCode(data: {
		promotionCode: string;
		priceId: string;
		defaultSource?: string;
		trialPeriodDays?: number | undefined;
	}): Promise<UserModel> {
		return this.http
			.post<UserModel>(`${environment.apiUrl}coupon/applyPromotionCode`, {
				promotionCode: data.promotionCode,
				priceId: data.priceId,
				defaultSource: data.defaultSource,
				trialPeriodDays: data.trialPeriodDays,
			})
			.pipe(take(1))
			.toPromise();
	}
	getPromotionCode(promotionalCode: string): Promise<PromotionCodeModel> {
		return this.http
			.get<PromotionCodeModel>(`${environment.apiUrl}coupon/getPromotionCode/?code=${promotionalCode}`)
			.pipe(take(1))
			.toPromise();
	}

	saveToStorage(promotionCode: PromotionCodeModel) {
		localStorage.setItem(PROMOTION_CODE_NAME, promotionCode.code);
	}

	removeFromStorage() {
		localStorage.removeItem(PROMOTION_CODE_NAME);
	}
}
