<div class="alert-container" *ngIf="globalAlerts.length > 0 && showAlert">
	<div class="alert box-shadow-md" *ngFor="let alert of globalAlerts">
		<div class="message">
			<div class="text">
				<h4 class="title">{{ alert.message?.title }}</h4>
				<span class="subtitle">{{ alert.message?.subtitle }}</span>
			</div>
		</div>
		<div class="btn-container">
			<button class="btn-lighter cta-primary" (click)="onRouteTo(alert)">
				{{ alert.message?.btn_text }}
			</button>
		</div>
	</div>
</div>
