import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { PlanModel } from './../../schemes/models/plan.model';

@Component({
	selector: 'app-subscription-element',
	templateUrl: './subscription-element.component.html',
	styleUrls: ['./subscription-element.component.scss'],
})
export class SubscriptionElementComponent implements OnInit {
	@Input() plan!: PlanModel;
	@Input() compactDesign: boolean = false;
	hideTrialPeriod = false;
	@Input() isLargerOn = true;
	@Input() isCoupon = false;
	@Input() isGift = false;
	@Output() handleBtnAction = new EventEmitter<PlanModel>();

	constructor(private authService: AuthService) {}

	ngOnInit(): void {
		this.hideTrialPeriod = this.authService.hideTrialPeriod();
		if (this.isCoupon && this.plan.discount_percentage == 100) {
			if (this.plan.enabled) {
				this.plan.isLarger = true;
			} else {
				this.plan.isLarger = false;
			}
		}
	}

	//Returns the proper string tag depending on the method selected.
	// @param {string} method : The selected method

	getProperTag(method?: string): string {
		switch (this.plan.type) {
			case 'Mensual':
				if (method == 'figure' || method == undefined) return 'monthly';
				if (method == 'frequency') return 'mes';
				break;

			case 'Trimestral':
				if (method == 'figure' || method == undefined) return 'quarterly';
				if (method == 'frequency') return 'trimestre';
				break;

			case 'Anual':
				if (method == 'figure' || method == undefined) return 'annually';
				if (method == 'frequency') return 'año';
				break;

			default:
				return '';
		}
		return '';
	}

	onBtnAction($event: any) {
		$event.preventDefault();
		this.handleBtnAction.next(this.plan);
	}
}
