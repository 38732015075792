import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { PlanProvider } from './../../providers/plan.provider';
import { AlertEnum } from './../../schemes/enums/alert.enum';
import { GiftModel } from './../../schemes/models/gift.model';
import { PlanModel } from './../../schemes/models/plan.model';
import { UserModel } from './../../schemes/models/user.model';
import { AuthService } from './../../services/auth.service';
import { GiftService } from './../../services/gift.service';

@Component({
	selector: 'app-redeem-gift',
	templateUrl: './redeem-gift.component.html',
	styleUrls: ['./redeem-gift.component.scss'],
})
export class RedeemGiftComponent implements OnInit, OnDestroy {
	urlCode!: string;
	gift!: GiftModel;
	plan!: PlanModel;
	giftedPlanInterval!: string;
	pageLoaded!: Promise<boolean>;
	currentUser!: UserModel;
	currentUser$!: Observable<UserModel>;
	cannotActivateGift = true;

	private currentUserSubscription!: Subscription;

	//Alerts
	alertSuccess = {
		heading: 'Regalo canjeado con éxito',
		message: new Array<string>(),
		type: AlertEnum['gift'],
		show: false,
		close: () => {
			this.alertSuccess.show = false;
			this.router.navigate(['clases']);
		},
		successMessage: {
			success: () => {
				this.alertSuccess.message = ['Esperamos que disfrutes de tu suscripción.'];
				this.alertSuccess.show = true;
			},
		},
	};

	alertError = {
		message: new Array<string>(),
		type: AlertEnum['error'],
		show: false,
		close: () => {
			this.alertError.show = false;
		},
		errorMessage: {
			error: () => {
				this.alertError.message = [
					'Se ha producido un error al intentar activar tu regalo.',
					'Prueba de nuevo más tarde.',
				];
				this.alertError.show = true;
			},
			onInitError: () => {
				this.alertError.message = ['Se ha producido un error.', 'Prueba de nuevo más tarde.'];
				this.alertError.show = true;
			},
		},
	};

	constructor(
		private giftService: GiftService,
		private _activatedRoute: ActivatedRoute,
		private planProvider: PlanProvider,
		private authService: AuthService,
		private router: Router
	) {}

	async ngOnInit(): Promise<void> {
		try {
			this.currentUser$ = this.authService.currentUserLogged;
			this.currentUserSubscription = this.currentUser$.subscribe((user: UserModel) => {
				this.currentUser = user || undefined;
				if (user)
					this.cannotActivateGift = ['active', 'past_due', 'unpaid', 'trialing'].includes(
						this.currentUser.membershipStatus
					);
			});

			this._activatedRoute.queryParams.subscribe(async params => {
				this.urlCode = params['code'];
				this.gift = await this.giftService.getGiftByCode(this.urlCode);
				await this.getGiftedPlan();
				if (this.plan) this.pageLoaded = Promise.resolve(true);
			});
		} catch (error) {
			this.alertError.errorMessage.onInitError();
		}
	}

	ngOnDestroy() {
		this.currentUserSubscription.unsubscribe();
	}

	onActivateGift() {
		if (this.currentUser) {
			this.activateGift();
		} else {
			localStorage.setItem('gift_url_code', this.urlCode);
			this.router.navigate(['signup']);
		}
	}

	private async activateGift() {
		try {
			await this.giftService.activateGift({ giftId: this.gift.id });
			this.alertSuccess.successMessage.success();
		} catch (error) {
			this.alertError.errorMessage.error();
		}
	}

	private async getGiftedPlan(): Promise<void> {
		let params = new HttpParams();
		params = params.set('stripePriceId', this.gift.price_id);

		this.plan = ((await this.planProvider.getAll(params)) as PlanModel[])[0];
		this.plan && this.getGiftedPlanInterval();
	}

	private getGiftedPlanInterval() {
		switch (this.plan.type) {
			case 'Día':
				this.giftedPlanInterval = '1 día';
				break;

			case 'Mensual':
				this.giftedPlanInterval = '1 mes';
				break;

			case 'Trimestral':
				this.giftedPlanInterval = '3 meses';
				break;

			case 'Anual':
				this.giftedPlanInterval = '1 año';
				break;

			default:
				this.giftedPlanInterval = 'acceso indefinido';
				break;
		}
	}
}
