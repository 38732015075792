export const environment = {
	production: true,
	apiUrl: 'https://dev-api.dharmaencalma.com/',
	placeholderImage:
		'https://firebasestorage.googleapis.com/v0/b/dharma-dev-ac6e8.appspot.com/o/class-reduced.jpg?alt=media&token=05d063ca-aead-4cde-88c2-141a12c98c50',
	logo: 'https://firebasestorage.googleapis.com/v0/b/dharma-dev-ac6e8.appspot.com/o/Captura_de_pantalla_2021_05_26_a_las_10_52_19_0e81d9a55d.png?alt=media&token=8476dcae-8ff5-4f8f-9384-3dfd418453ee',
	stripeApiKey:
		'pk_test_51IfJtaAocvw1RxghhoB5f7ExpbIpmeT7ZJRN7n0rfeO12obk8pY0NiJLNx4wd1iqM2xqD001As3UO7p46jc5CGyP00AnqIqXnc',
	title: 'Dharma en calma',
	site_name: 'Dharma en Calma',
	url: 'https://dev-web.dharmaencalma.com',
};

export const stripeApiKey = () => {
	return environment.stripeApiKey;
};
