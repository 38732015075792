import { Pipe, PipeTransform } from '@angular/core';
import { MembershipStatusEnum } from 'src/app/schemes/enums/membership-status.enum';
import { AuthService } from 'src/app/services/auth.service';

@Pipe({ name: 'membershipContentPipe' })
export class MembershipContentPipe implements PipeTransform {
	constructor(private authService: AuthService) {}

	transform(isFreeClass: boolean): boolean {
		const membershipStatus = this.authService.userMembership;
		const result = membershipStatus
			? [MembershipStatusEnum.active, MembershipStatusEnum.trialing].includes(membershipStatus)
			: isFreeClass;
		return result;
	}
}
