import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SeoPagesModel } from 'src/app/schemes/models/seo.model';
import { UserModel } from 'src/app/schemes/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { SeoService } from 'src/app/services/seo.service';
import { matchPassword } from 'src/app/validators/match-password.validator';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.scss', '../login/login.component.scss'],
})
export class SignupComponent implements OnInit {
	signupForm!: FormGroup;
	signupFormFirstPage!: FormGroup;
	signupFormLastPage!: FormGroup;
	user!: UserModel;
	emailErrors: Array<String> = [];
	submitted = false;
	firstPageSubmitted = false;
	termsAccepted = false;
	newsletterAccepted = false;
	termsAcceptedShowError = false;

	// Form pages
	formPage = 1;

	providerName!: 'google' | 'facebook';
	environment = environment;

	//Password visibility
	pwdVisibility = {
		password: false,
		confirmPassword: false,
	};

	@ViewChild('password') passwordElement!: ElementRef;
	@ViewChild('confirmPassword') confirmPasswordElement!: ElementRef;

	constructor(
		private fb: FormBuilder,
		private auth: AuthService,
		private alertService: AlertService,
		private router: Router,
		private seoService: SeoService
	) {
		this.createForm();
	}

	async ngOnInit() {
		await this.addSeoMetadata();
	}

	async addSeoMetadata() {
		let seo: SeoPagesModel = await this.seoService.getSeo();
		this.seoService.addSeoMetadata(seo.signup.title, seo.signup, 'registro');
	}

	async onSubmit(): Promise<void> {
		this.submitted = true;
		this.termsAcceptedShowError = false;
		if (this.signupFormFirstPage.valid && this.signupFormLastPage.valid) {
			if (!this.termsAccepted) {
				this.termsAcceptedShowError = true;
				return;
			}

			this.emailErrors.length = 0; //Emptying array of errors.

			let body: Object = {
				username: this.auth.getUsername(this.signupFormLastPage.value.name),
				name: this.signupFormLastPage.value.name,
				surname: this.signupFormLastPage.value.surname,
				email: this.signupFormFirstPage.value.email,
				password: this.signupFormFirstPage.value.password,
				reachedBy: this.signupFormLastPage.value.reach,
				newsletter: this.newsletterAccepted,
			};

			try {
				const { user } = await this.auth.signup(body);
				this.user = user;
				if (this.user) {
					// this.alertService.show('Usuario creado con éxito', 'success', undefined, true);
					//TODO esperar a que se cierre la ventana?
					this.router.navigate(['unconfirmed']);
				} else {
					this.alertService.show('Se ha producido un error', 'error');
					//this.router.navigate(['clases']);
				}
			} catch (e: any) {
				console.log(e.error);
				var error: String = e?.error?.message[0]?.messages[0]?.id || '';
				this.decodeError(error);
			}
		}
	}

	private createForm(): void {
		this.signupFormFirstPage = this.fb.group(
			{
				email: ['', [Validators.required, Validators.email]],
				password: ['', [Validators.required, Validators.minLength(8)]],
				confirmPassword: ['', Validators.required],
			},
			{
				validator: matchPassword('password', 'confirmPassword'),
			}
		);
		this.signupFormLastPage = this.fb.group({
			name: ['', Validators.required],
			surname: ['', [Validators.required]],
			reach: ['', [Validators.required]],
		});

		this.signupForm = this.fb.group({
			firstPage: this.signupFormFirstPage,
			lastPage: this.signupFormLastPage,
		});
	}

	public onCheck(event: Event, type: string): void {
		switch (type) {
			case 'terms':
				this.termsAccepted = (event.target as HTMLInputElement).checked;
				this.termsAcceptedShowError = false;
				break;
			case 'newsletter':
				this.newsletterAccepted = (event.target as HTMLInputElement).checked;
				break;

			default:
				break;
		}
	}

	private decodeError(error: String) {
		switch (error) {
			case 'Auth.form.error.email.taken':
				this.emailErrors.push('El email introducido está en uso');
				break;

			default:
				this.emailErrors.push('Se ha producido un error en el registro.');
				break;
		}
		this.formPage = 1;
	}

	onChangePage(toPage: number) {
		switch (toPage) {
			case 1:
				this.formPage = 1;
				break;

			case 2:
				if (this.signupFormFirstPage.valid) {
					this.formPage = 2;
				}
				break;
			default:
				break;
		}
	}

	onSocialLogin(provider: 'google' | 'facebook') {
		localStorage.setItem('provider', provider);
		this.providerName = provider;
		console.log(`${this.environment.apiUrl}connect/${provider}`);
		window.location.href = `${this.environment.apiUrl}connect/${provider}`;
	}

	onToggleVisibility(element: string) {
		switch (element) {
			case 'confirmPassword':
				if (this.pwdVisibility.confirmPassword) {
					this.confirmPasswordElement.nativeElement.type = 'password';
				} else {
					this.confirmPasswordElement.nativeElement.type = 'text';
				}
				this.pwdVisibility.confirmPassword = !this.pwdVisibility.confirmPassword;
				break;

			case 'password':
				if (this.pwdVisibility.password) {
					this.passwordElement.nativeElement.type = 'password';
				} else {
					this.passwordElement.nativeElement.type = 'text';
				}
				this.pwdVisibility.password = !this.pwdVisibility.password;
				break;

			default:
				break;
		}
	}
}
