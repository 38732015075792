import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class VideoService {
	protected readonly VIDEO_REGEX = {
		YOUTUBE_URL: /^https:\/\/www.youtube.com\/watch\?v=([a-zA-Z0-9-_]{11})(&.+)?$/g,
		YOUTUBE_ID: /^([a-zA-Z0-9-_]{11})$/g,
		VIMEO_URL: /^https:\/\/vimeo.com\/(\d+)(\?.+)?$/g,
		VIMEO_ID: /^(\d+)$/g,
	} as const;

	getVideoURL(string: string): string | null {
		try {
			if (this.isVimeoVideo(string)) return this.getVimeoEmbedURL(string);
			if (this.isYouTubeVideo(string)) return this.getYouTubeEmbedURL(string);
			return null;
		} catch (error) {
			return null;
		}
	}

	isVimeoVideo(string: string): boolean {
		return (
			new RegExp(this.VIDEO_REGEX.VIMEO_URL).test(string) ||
			new RegExp(this.VIDEO_REGEX.VIMEO_ID).test(string)
		);
	}

	isYouTubeVideo(string: string): boolean {
		return (
			new RegExp(this.VIDEO_REGEX.YOUTUBE_URL).test(string) ||
			new RegExp(this.VIDEO_REGEX.YOUTUBE_ID).test(string)
		);
	}

	private getVimeoEmbedURL(string: string): string | null {
		const id = (new RegExp(this.VIDEO_REGEX.VIMEO_ID).exec(string) ||
			new RegExp(this.VIDEO_REGEX.VIMEO_URL).exec(string) ||
			[])[1];
		if (!id) return null;
		return `https://player.vimeo.com/video/${id}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media`;
	}

	private getYouTubeEmbedURL(string: string): string | null {
		const id = (new RegExp(this.VIDEO_REGEX.YOUTUBE_ID).exec(string) ||
			new RegExp(this.VIDEO_REGEX.YOUTUBE_URL).exec(string) ||
			[])[1];
		if (!id) return null;
		return `https://www.youtube.com/embed/${id}?origin=${environment.url}&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`;
	}
}
