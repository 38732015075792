import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertEnum } from 'src/app/schemes/enums/alert.enum';
import { AuthService } from 'src/app/services/auth.service';
import { AccountsService } from './../../../services/accounts.service';

@Component({
	selector: 'app-forgotten-password',
	templateUrl: './forgotten-password.component.html',
	styleUrls: ['../login.component.scss', './forgotten-password.component.scss'],
})
export class ForgottenPasswordComponent implements OnInit {
	forgottenPasswordForm!: FormGroup;
	submitted: boolean = false;

	alert = {
		message: new Array<string>(),
		type: AlertEnum['password'],
		show: false,
		close: () => {
			this.alert.show = false;
		},
	};

	constructor(private fb: FormBuilder, private auth: AuthService, private accountsService: AccountsService) {
		this.createForm();
	}

	async ngOnInit(): Promise<void> {}

	async onSubmit(): Promise<void> {
		this.submitted = true;
		if (this.forgottenPasswordForm.valid) {
			const email = this.forgottenPasswordForm.value.email;
			try {
				if (
					(await this.accountsService.getEmailProvider(email)).provider == 'local' &&
					this.auth.sendRecoveryEmailIfExists(email)
				) {
					this.alert.message = [
						'Hemos enviado un enlace para restablecer tu contraseña a ' +
							this.forgottenPasswordForm.value.email,
					];
					this.alert.show = true;
				}
				this.submitted = false;
				this.forgottenPasswordForm.reset();
			} catch (e) {
				this.submitted = false;
				this.forgottenPasswordForm.reset();
			}
		}
	}

	private createForm(): void {
		this.forgottenPasswordForm = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
		});
	}
}
