import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import Plyr from 'plyr';
import { UserModel } from 'src/app/schemes/models/user.model';
import { VideoModel } from 'src/app/schemes/models/video.model';
import { AuthService } from 'src/app/services/auth.service';
import { VideoService } from 'src/app/services/video.service';
import { MembershipContentPipe } from 'src/app/shared/pipes/membership-content/membership-content.pipe';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-video-player',
	templateUrl: './video-player.component.html',
	styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
	@Input() sourceVideo!: VideoModel;
	@Input() playerId = 0;
	@Input() user!: UserModel;
	@Input() type!: 'course' | 'class';
	@Input() autoplay = false;
	@Output() userClickOnPlay = new EventEmitter();
	@Output() skipIntro = new EventEmitter();
	player!: Plyr;
	@Input() isSelectedVideoAuthorized!: boolean;
	isFirstPlayIntent = true; //In order to only capture first click on "play button" from user
	isPlaying = false;
	videoUrl: string = '';
	//TODO pipe
	sourceImgPath = '';

	constructor(
		private router: Router,
		private authService: AuthService,
		private membershipContent: MembershipContentPipe,
		public sanitizer: DomSanitizer,
		private videoService: VideoService,
		private el: ElementRef,
		private render: Renderer2
	) {}

	ngOnInit(): void {
		if (!(this.sourceVideo && this.sourceVideo.cover_image))
			this.sourceImgPath = environment.placeholderImage;
		else if (environment.production) this.sourceImgPath = this.sourceVideo.cover_image.url;
		else
			this.sourceImgPath =
				environment.apiUrl.substr(0, environment.apiUrl.length - 1) + this.sourceVideo.cover_image.url;

		if (this.type == 'class')
			this.isSelectedVideoAuthorized = !!(
				this.sourceVideo && this.membershipContent.transform(this.sourceVideo.is_free)
			);
		if (this.sourceVideo) {
			this.videoUrl = this.videoService.getVideoURL(this.sourceVideo.video_url) ?? '';
			!this.player && this.initPlayer();
		}
	}

	initPlayer() {
		setTimeout(async _ => {
			this.player = new Plyr('#player-' + this.playerId);
			this.setOnPlayingListener();
			this.sourceVideo.is_intro && this.setOnEndedListener();
			if (
				this.autoplay &&
				((this.type == 'class' && this.isSelectedVideoAuthorized) || this.type == 'course')
			) {
				await this.player.play();
			}
			this.videoService.isYouTubeVideo(this.sourceVideo.video_url) && this.removeYoutubeUI();
		}, 500);
	}

	setOnPlayingListener() {
		this.player.on('playing', (event: any) => {
			this.isPlaying = true;
			if (this.isFirstPlayIntent) {
				this.userClickOnPlay.emit();
				this.isFirstPlayIntent = false;
			}
		});
	}

	setOnEndedListener() {
		this.player.on('ended', (event: any) => {
			this.onSkipIntro();
		});
	}

	onRequestContent() {
		this.authService.currentUser &&
		(!this.authService.currentUser.stripeCustomerId || !this.authService.currentUser.stripeSubscriptionId)
			? this.router.navigate(['suscripciones'])
			: this.router.navigate(['signup']);
	}

	onSkipIntro() {
		this.skipIntro.emit();
	}

	/**
	 * YouTube title, share buttons and logo can be hidden by setting them out of the iframe viewport.This function applies the proper styles to achieve the desired behavior.
	 */
	private removeYoutubeUI() {
		const container = this.el.nativeElement.querySelector(`.plyr__video-wrapper.plyr__video-embed`);
		const iframe = container.querySelector('iframe');

		const containerStyle = `overflow: hidden; width: 100%; aspect-ratio: 16/9; pointer-events: none;`;
		this.render.setAttribute(container, 'style', containerStyle);

		const iframeStyle = `width: 300%; height: 100%; margin-left: -100%; user-select:none;`;
		this.render.setAttribute(iframe, 'style', iframeStyle);
	}
}
