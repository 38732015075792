export enum AlertEnum {
	error = 'error',
	gift = 'gift',
	modification = 'modification',
	pay = 'pay',
	register = 'register',
	password = 'password',
	email = 'email',
	check = 'check',
	question = 'question',
	information = 'information',
}
