<nav class="navbar-sections">
	<ul class="default-media">
		<li class="search-icon" (click)="onSearchBarVisibility(true, $event)">
			<img src="assets/images/icons/magnifying-glass.svg" alt="search-icon" />
		</li>
		<li *ngFor="let item of commonRoutes">
			<a class="navbar-section" [routerLink]="item.routerLink" routerLinkActive="active">{{ item.label }}</a>
		</li>
		<ng-container *ngIf="!loginStatus; else userLoggedIn">
			<li
				*ngFor="let item of notLoggedInRoutes"
				[ngClass]="{ signup: item.label == 'Empieza ahora' }"
				(click)="onClickGoTo(item)"
			>
				<a
					[ngClass]="{ signup: item.label == 'Empieza ahora' }"
					class="navbar-section"
					[routerLink]="item.routerLink"
					routerLinkActive="active"
					>{{ item.label }}</a
				>
			</li>
		</ng-container>
		<ng-template #userLoggedIn>
			<li *ngFor="let item of loggedInRoutes">
				<a class="navbar-section" [routerLink]="item.routerLink" routerLinkActive="active">{{
					item.label
				}}</a>
			</li>
		</ng-template>
		<ng-container *ngIf="loginStatus">
			<div class="user-container" (clickOutside)="openDropdown = false">
				<img [src]="userAvatarSrc" class="user-icon" (click)="toggleDropdown()" />
				<div class="dropdown" *ngIf="openDropdown">
					<ul class="dropdown-content">
						<li class="greetings">
							<span
								>Hola,
								{{
									currentUser.name?.length > 20 ? (currentUser.name | slice: 0:17) + '...' : currentUser.name
								}}</span
							>
						</li>
						<li (click)="onClickDropdownElement('cuenta')">
							<a>Mi cuenta</a>
						</li>
						<li>
							<a (click)="logout()">Cerrar sesión</a>
						</li>
					</ul>
				</div>
			</div>
		</ng-container>
	</ul>
	<div class="mobile-media">
		<div class="navbar-buttons" (clickOutside)="openDropdownMobile = false">
			<img
				class="search-icon-mobile"
				src="assets/images/icons/magnifying-glass.svg"
				alt="search-icon"
				(click)="onSearchBarVisibility(true, $event)"
			/>
			<img
				class="hamburger-icon"
				(click)="toggleDropdown()"
				src="assets/images/icons/hamburger-menu.svg"
				alt="hamburger-menu-icon"
			/>
		</div>
		<div class="dropdown-menu-mobile" *ngIf="openDropdownMobile">
			<div class="dropdown-mobile-subsection">
				<ul class="mobile-list">
					<li *ngFor="let item of commonRoutes" (click)="onClickDropdownElement(item.routerLink)">
						<a class="navbar-section" [routerLink]="item.routerLink" routerLinkActive="active">{{
							item.label
						}}</a>
					</li>
					<ng-container *ngIf="loginStatus">
						<li *ngFor="let item of loggedInRoutes" (click)="onClickDropdownElement(item.routerLink)">
							<a [routerLink]="item.routerLink" routerLinkActive="active">{{ item.label }}</a>
						</li>
					</ng-container>
					<ng-container *ngIf="!loginStatus">
						<li *ngFor="let item of notLoggedInRoutes" (click)="onClickDropdownElement(item.routerLink)">
							<a [routerLink]="item.routerLink" routerLinkActive="active">{{
								item.label != 'Empieza ahora' ? item.label : 'Registro'
							}}</a>
						</li>
					</ng-container>
				</ul>
			</div>
			<div class="dropdown-mobile-subsection" *ngIf="loginStatus">
				<ul class="mobile-list">
					<li (click)="onClickDropdownElement('cuenta')" (click)="onClickDropdownElement('cuenta')">
						<a routerLink="/cuenta" routerLinkActive="active">Mi cuenta</a>
					</li>
					<li>
						<a href="" class="logout" (click)="logout()">Cerrar sesión</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</nav>
<app-search-bar (closeSearchBar)="onSearchBarVisibility(false)"></app-search-bar>
