import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface AccountProviderInterface {
	provider: 'facebook' | 'google' | 'local';
}

@Injectable({
	providedIn: 'root',
})
export class AccountsService {
	constructor(private http: HttpClient) {}

	getEmailProvider(email: string): Promise<AccountProviderInterface> {
		return this.http
			.get<AccountProviderInterface>(`${environment.apiUrl}users/provider/${email}`)
			.pipe(take(1))
			.toPromise();
	}
}
