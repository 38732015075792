import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
@Injectable({
	providedIn: 'root',
})
export class AlertService {
	constructor() {}

	show(msg: string, type: 'success' | 'error', time: number = 25000, showConfirmButton: boolean = false) {
		swal.fire({
			position: 'center',
			icon: type,
			title: `${msg}`,
			showConfirmButton,
			timer: time,
		});
	}
}
