import { Component, OnInit } from '@angular/core';
import { SeoPagesModel } from 'src/app/schemes/models/seo.model';
import { SeoService } from 'src/app/services/seo.service';

@Component({
	selector: 'app-privacy-policy',
	templateUrl: './privacy-policy.component.html',
	styleUrls: ['./privacy-policy.component.scss', '../legal.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
	constructor(private seoService: SeoService) {}

	async ngOnInit() {
		await this.addSeoMetadata();
	}

	async addSeoMetadata() {
		let seo: SeoPagesModel = await this.seoService.getSeo();
		this.seoService.addSeoMetadata(seo.privacypolicy.title, seo.privacypolicy, 'política de privacidad');
	}
}
