import { Injectable } from '@angular/core';
@Injectable({
	providedIn: 'root',
})
export class UtilsService {
	constructor() {}

	//Convert a email from example@example.com to e******@example.com

	public encodeEmail(email: string): string {
		let user = email.substring(0, email.indexOf('@'));
		if (user.length > 1) {
			let emailEncoded =
				user.substring(0, 1) +
				user.substring(1, user.length).replace(/./g, '*') +
				email.substring(email.indexOf('@'), email.length);
			return emailEncoded;
		}
		return email;
	}
}
