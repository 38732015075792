import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
@Injectable({
	providedIn: 'root',
})
export class BaseProvider<T> {
	collection: string;

	constructor(@Inject(String) collection: string, protected http: HttpService) {
		this.collection = collection;
	}

	getAll(params?: HttpParams): Promise<T[]> {
		return this.http.get<T[]>(`${this.collection}`, undefined, params);
	}

	getById(id: string, params?: HttpParams): Promise<T> {
		return this.http.get<T>(`${this.collection}/${id}`, undefined, params);
	}

	save(entity: T): Promise<T> {
		return this.http.post(`${this.collection}`, entity);
	}

	/*update(entity: T): Promise<T> {
    return this.http.patch<T>(
      `${this.collection}/${entity._id}`,
      { id: entity._id, data: entity },
      { database: this.database || HttpService.currentDb }
    );
  }

  put(entity: T): Promise<T> {
    return this.http.put<T>(
      `${this.collection}/${entity._id}`,
      { id: entity._id, data: entity },
      { database: this.database || HttpService.currentDb }
    );
  }

  delete(id: string): Promise<void> {
    return this.http.delete(`${this.collection}/${id}`, { database: this.database || HttpService.currentDb });
  }

  count(filters: Record<string, unknown>): Promise<T<{ count: number }>> {
    return this.http.get<T<{ count: number }>>(
      `${this.collection}/count`,
      { database: this.database || HttpService.currentDb },
      filters
    );
  }*/
}
