import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AlertModule } from '../components/alert/alert.module';
import { CardPreviewModule } from '../components/card-preview/card-preview.module';
import { ClassElementModule } from '../components/class-element/class-element.module';
import { ConfirmModule } from '../components/confirm/confirm.module';
import { CourseElementModule } from '../components/course-element/course-element.module';
import { RelatedContentModule } from '../components/related-content/related-content.module';
import { VideoPlayerModule } from '../components/video-player/video-player.module';
import { SubscritionElementModule } from './../components/subscription-element/subscription-element.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ClassElementModule,
		CourseElementModule,
		RelatedContentModule,
		VideoPlayerModule,
		CardPreviewModule,
		AlertModule,
		ConfirmModule,
	],
	exports: [
		CommonModule,
		FormsModule,
		ClassElementModule,
		CourseElementModule,
		SubscritionElementModule,
		RelatedContentModule,
		VideoPlayerModule,
		CardPreviewModule,
		AlertModule,
		ConfirmModule,
	],
	declarations: [],
	providers: [],
})
export class SharedModule {}
