import { Injectable } from '@angular/core';
import { CourseModel } from '../schemes/models/course.model';
import { HttpService } from '../services/http.service';
import { BaseProvider } from './base.provider';
@Injectable({
	providedIn: 'root',
})
export class CourseProvider extends BaseProvider<CourseModel> {
	constructor(http: HttpService) {
		super('courses', http);
	}
}
