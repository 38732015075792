import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-cookies-dialog',
	templateUrl: './cookies-dialog.component.html',
	styleUrls: ['./cookies-dialog.component.scss'],
})
export class CookiesDialogComponent implements OnInit {
	@Output() cookiesAccepted = new EventEmitter<boolean>();

	constructor() {}

	ngOnInit(): void {}

	onAcceptCookies() {
		localStorage.setItem('cookies_accepted', 'true');
		this.cookiesAccepted.emit(true);
	}
}
