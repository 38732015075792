<div class="page-container">
	<header class="page-title">
		<h3>Crear cuenta</h3>
	</header>
	<div class="flex-column">
		<div class="form-section">
			<form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
				<ng-container *ngIf="formPage == 1; else page2" formGroupName="firstPage">
					<div
						class="input-group"
						[class.error-found]="
							(signupForm.invalid && submitted && signupFormFirstPage.controls.email?.errors) ||
							emailErrors.length > 0
						"
					>
						<label for="email">Correo electrónico</label>
						<input type="email" name="email" formControlName="email" />
						<div
							class="errors-container"
							*ngIf="signupFormFirstPage.controls.email?.errors || emailErrors.length > 0"
						>
							<span
								*ngIf="
									signupFormFirstPage.controls.email?.errors?.required &&
									(signupFormFirstPage.controls.email?.touched || submitted)
								"
								>Este campo es obligatorio</span
							>
							<span
								*ngIf="
									signupFormFirstPage.controls.email?.errors?.email &&
									!signupFormFirstPage.controls.email?.pristine
								"
								>Escriba un email válido
							</span>
							<span *ngIf="emailErrors.length > 0">{{ emailErrors[0] }}</span>
						</div>
					</div>
					<div
						class="input-group"
						[class.error-found]="
							signupForm.invalid && submitted && signupFormFirstPage.controls.password?.errors
						"
					>
						<label for="password">Contraseña</label>
						<div class="password-visibility-toggle">
							<input
								#password
								type="password"
								name="password"
								formControlName="password"
								placeholder="Al menos 8 caracteres"
							/>
							<i
								class="fas fa-eye"
								(click)="onToggleVisibility('password')"
								*ngIf="!pwdVisibility.password"
							></i>
							<i
								class="fas fa-eye-slash"
								(click)="onToggleVisibility('password')"
								*ngIf="pwdVisibility.password"
							></i>
						</div>

						<div class="errors-container" *ngIf="signupFormFirstPage.controls.password?.errors">
							<span
								*ngIf="
									signupFormFirstPage.controls.password?.errors?.required &&
									(signupFormFirstPage.controls.password?.touched || submitted)
								"
								>Este campo es obligatorio
							</span>
							<span *ngIf="signupFormFirstPage.controls.password?.errors?.minlength"
								>La contraseña debe de tener al menos 8 caracteres
							</span>
						</div>
					</div>
					<div
						class="input-group"
						[class.error-found]="
							signupForm.invalid && submitted && signupFormFirstPage.controls.confirmPassword?.errors
						"
					>
						<label for="password">Confirma tu contraseña</label>
						<div class="password-visibility-toggle">
							<input #confirmPassword type="password" name="password" formControlName="confirmPassword" />
							<i
								class="fas fa-eye"
								(click)="onToggleVisibility('confirmPassword')"
								*ngIf="!pwdVisibility.confirmPassword"
							></i>
							<i
								class="fas fa-eye-slash"
								(click)="onToggleVisibility('confirmPassword')"
								*ngIf="pwdVisibility.confirmPassword"
							></i>
						</div>
						<div class="errors-container" *ngIf="signupFormFirstPage.controls.confirmPassword?.errors">
							<span *ngIf="signupFormFirstPage.controls.confirmPassword?.errors?.required && submitted"
								>Este campo es obligatorio
							</span>
							<span *ngIf="signupFormFirstPage.controls.confirmPassword?.errors?.matchPassword"
								>Las contraseñas no coinciden
							</span>
						</div>
					</div>
				</ng-container>

				<ng-template #page2 formGroupName="lastPage">
					<div
						class="input-group"
						[class.error-found]="
							signupFormLastPage.invalid && submitted && signupFormLastPage.controls.name?.errors
						"
					>
						<label for="name">Nombre</label>
						<input type="text" name="name" formControlName="name" />
						<div class="errors-container" *ngIf="signupFormLastPage.controls.name?.errors">
							<span *ngIf="signupFormLastPage.controls.name?.errors?.required && submitted"
								>Este campo es obligatorio</span
							>
						</div>
					</div>
					<div
						class="input-group"
						[class.error-found]="
							signupFormLastPage.invalid && submitted && signupFormLastPage.controls.surname?.errors
						"
					>
						<label for="name">Apellidos</label>
						<input type="text" name="name" formControlName="surname" />
						<div class="errors-container" *ngIf="signupFormLastPage.controls.surname?.errors">
							<span *ngIf="signupFormLastPage.controls.surname?.errors?.required && submitted"
								>Este campo es obligatorio</span
							>
						</div>
					</div>
					<div
						class="input-group select-input"
						[class.error-found]="
							signupFormLastPage.invalid && submitted && signupFormLastPage.controls.reach?.errors
						"
					>
						<label for="name">¿Cómo nos conociste?</label>
						<div class="select-wrapper">
							<select name="reach" id="reach" formControlName="reach">
								<option value="" selected disabled hidden>Selecciona una opción</option>
								<option value="instagram">Instagram</option>
								<option value="facebook">Facebook</option>
								<option value="youtube">YouTube</option>
								<option value="web">Web</option>
								<option value="recomendacion">Por recomendación personal</option>
								<option value="otros">Otros</option>
							</select>
						</div>
						<div class="errors-container" *ngIf="signupFormLastPage.controls.reach?.errors">
							<span *ngIf="signupFormLastPage.controls.reach?.errors?.required && submitted"
								>Este campo es obligatorio</span
							>
						</div>
					</div>

					<div class="checkboxes-container">
						<div class="checkbox-label-row">
							<div class="checkbox-wrapper" [class.error]="termsAcceptedShowError">
								<input
									type="checkbox"
									name=""
									id="checkbox-accept-terms"
									(change)="onCheck($event, 'terms')"
									[checked]="termsAccepted"
								/>
								<label for="checkbox-accept-terms"
									>He leído y acepto la
									<a [routerLink]="['/legal/privacidad']" target="_blank">política de privacidad.</a></label
								>
							</div>
							<div class="errors-container" *ngIf="termsAcceptedShowError">
								<span>Tienes que aceptar la política de privacidad para continuar</span>
							</div>
						</div>
						<div class="checkbox-label-row">
							<div class="checkbox-wrapper">
								<input
									type="checkbox"
									name=""
									id="checkbox-newsletter"
									(change)="onCheck($event, 'newsletter')"
									[checked]="newsletterAccepted"
								/>
								<label for="checkbox-newsletter"
									>Deseo recibir noticias e información relevante por e-mail.</label
								>
							</div>
						</div>
					</div>
				</ng-template>
				<div class="section-indicator">
					<div class="indicator" [class.active]="formPage == 1"></div>
					<div class="indicator" [class.active]="formPage == 2"></div>
				</div>

				<div class="form-buttons">
					<ng-container *ngIf="formPage == 1; else page2Buttons">
						<button
							[disabled]="!signupFormFirstPage.valid"
							class="submit box-shadow-md"
							(click)="onChangePage(2)"
						>
							Continuar
						</button>
					</ng-container>
					<ng-template #page2Buttons>
						<button class="btn-lighter accent" (click)="onChangePage(1)">Volver atrás</button>
						<button class="submit box-shadow-md" type="submit">Registrarse</button>
					</ng-template>
				</div>
			</form>
		</div>
		<div class="third-parties-login-buttons-container">
			<span>O entra con</span>
			<button class="box-shadow-lg google" (click)="onSocialLogin('google')">
				<img src="assets/images/icons/social/google.svg" alt="google-icon" /> Google
			</button>
			<button class="box-shadow-lg fb" (click)="onSocialLogin('facebook')">
				<img src="assets/images/icons/social/facebook.svg" alt="facebook-icon" />Facebook
			</button>
			<label class="agreement"
				>Al registrarte mediante Google o Facebook estarás aceptando nuestra
				<a [routerLink]="['/legal/privacidad']" target="_blank">política de privacidad.</a></label
			>
		</div>
		<div class="additional-container">
			<span>¿Ya tienes una cuenta? <a [routerLink]="['/login']"> Inicia sesión</a></span>
		</div>
	</div>
</div>

<ng-container *ngIf="emailErrors.length > 0">
	<p>Errores de email</p>
	<label *ngFor="let item of emailErrors">{{ item }}</label>
</ng-container>
