import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cardBrandPipe' })
export class CardBrandPipe implements PipeTransform {
	renamedBrands: {
		[key: string]: string;
	} = {
		mastercard: 'mc',
		'american express': 'ae',
		'diners club': 'dc',
	};

	constructor() {}

	transform(brand: string): string {
		return this.renamedBrands[brand]
			? (brand = this.renamedBrands[brand].toUpperCase())
			: brand.toUpperCase();
	}
}
