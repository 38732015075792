import { Injectable } from '@angular/core';
import { UserModel } from '../schemes/models/user.model';
import { HttpService } from '../services/http.service';

@Injectable({
	providedIn: 'root',
})
export class UserProvider {
	constructor(private http: HttpService) {}

	async getMe(): Promise<UserModel> {
		return await this.http.get('users/me');
	}

	async checkIfUserExistsByEmail(email: string): Promise<boolean> {
		return await this.http.get<boolean>(`users/emailexists/` + email);
	}
}
