import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StripeUserCard } from 'src/app/schemes/models/stripe-user-cards.model';

@Component({
	selector: 'app-card-preview',
	templateUrl: './card-preview.component.html',
	styleUrls: ['./card-preview.component.scss'],
})
export class CardPreviewComponent implements OnInit {
	@Input() card!: StripeUserCard & { brandAcronym?: string };
	@Input() hasRadioButtons = false;
	@Input() subscriptionFailure = false;
	@Input() defaultCardId = '';
	@Output() changeSelection = new EventEmitter<StripeUserCard>();
	@Output() setDefaultCard = new EventEmitter<StripeUserCard>();
	@Output() deleteCard = new EventEmitter<StripeUserCard>();
	@Output() retryPaymentIntent = new EventEmitter<StripeUserCard>();

	now = new Date();
	stripeCardExpirationError = false;

	renamedBrands: {
		[key: string]: string;
	} = {
		visa: "'\f1f0'",
		mastercard: "'\f1f1",
		'american express': 'ae',
		'diners club': 'dc',
	};

	constructor() {}

	ngOnInit(): void {
		if (this.card.brand.indexOf(' ') || this.card.brand.length >= 4) {
			this.tryBrandRename(this.card.brand);
		}

		if (this.card.expiration_date < this.now && !this.card.isValid) {
			this.stripeCardExpirationError = true;
		}
	}

	onSelectCard() {
		this.changeSelection.next(this.card);
	}

	onSetDefaultCard() {
		this.setDefaultCard.next(this.card);
	}

	onRetryPayment() {
		this.retryPaymentIntent.next(this.card);
	}

	onDeleteCard() {
		this.deleteCard.next(this.card);
	}

	tryBrandRename(brand: string) {
		this.renamedBrands[brand]
			? (this.card.brandAcronym = this.renamedBrands[brand])
			: (this.card.brandAcronym = brand);
	}
}
