import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RelatedContentModel } from 'src/app/schemes/models/related-content.model';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-related-content',
	templateUrl: './related-content.component.html',
	styleUrls: ['./related-content.component.scss'],
})
export class RelatedContentComponent implements OnInit {
	@Input() relatedContent!: RelatedContentModel | undefined;
	@Input() sourceImgPath = '';
	environment = environment;
	constructor(private router: Router) {}

	ngOnInit(): void {}

	redirection(route: string) {
		this.router.navigate([route]);
	}
}
