import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SeoPagesModel } from 'src/app/schemes/models/seo.model';
import { AuthService } from 'src/app/services/auth.service';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';
import { AccountsService } from './../../services/accounts.service';

declare var window: any;
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	loginForm!: FormGroup;
	response: any;
	user!: any;
	submitted: boolean = false;
	loginErrors: Array<String> = [];
	environment = environment;
	providerName!: 'google' | 'facebook';
	providerOfEmailInserted!: 'local' | 'google' | 'facebook';

	//Password visibility toggle
	@ViewChild('password') passwordElement!: ElementRef;
	pwdVisibility = false;

	constructor(
		private fb: FormBuilder,
		private auth: AuthService,
		private router: Router,
		private route: ActivatedRoute,
		private seoService: SeoService,
		private accountsService: AccountsService
	) {
		this.createForm();
	}

	async ngOnInit(): Promise<void> {
		try {
			const queryParams = this.route.snapshot.queryParams;
			this.providerName = localStorage.getItem('provider') as 'google' | 'facebook';
			const validParams = !!(queryParams && queryParams.access_token && this.providerName);
			validParams && (await this.auth.socialAuthCallback(queryParams.access_token, this.providerName));
			await this.addSeoMetadata();
		} catch (e) {
			this.handleError(e);
		}
	}

	async addSeoMetadata() {
		let seo: SeoPagesModel = await this.seoService.getSeo();
		this.seoService.addSeoMetadata(seo.login.title, seo.login, 'inicio de sesión');
	}

	async onSubmit(): Promise<void> {
		this.submitted = true;
		if (this.loginForm.valid) {
			this.loginErrors.length = 0; //Emptying array of errors.

			let body: Object = {
				identifier: this.loginForm.value.email,
				password: this.loginForm.value.password,
			};

			try {
				await this.auth.login(body);
			} catch (e) {
				try {
					//Check if the error is because of wrong provider
					this.providerOfEmailInserted = (
						await this.accountsService.getEmailProvider(this.loginForm.value.email)
					).provider;

					if (this.providerOfEmailInserted !== 'local') {
						this.decodeError('Auth.email.provider.wrong');
					} else {
						this.handleError(e);
					}
				} catch (providerError) {
					//Email not found
					this.decodeError('Auth.form.error.invalid');
				}
			}
		}
	}

	handleError(e: any) {
		let error: string = e?.error?.message[0]?.messages[0]?.id;
		this.decodeError(error);
	}

	private createForm(): void {
		this.loginForm = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
		});
	}

	private decodeError(error: String) {
		let msg = '';
		switch (error) {
			case 'Auth.form.error.invalid':
				msg = 'El email y/o la contraseña no son correctos';

				break;

			case 'Auth.form.error.confirmed':
				msg = `Cuenta de correo no verificada`;
				this.router.navigate(['unconfirmed']);
				break;

			case 'Auth.form.error.email.taken':
				msg = 'El email ya está en uso';

				break;

			case 'Auth.email.provider.wrong':
				let providerCapitalize =
					this.providerOfEmailInserted.charAt(0).toUpperCase() + this.providerOfEmailInserted.slice(1);
				msg = 'Inicia sesión con el método usado durante el registro (' + providerCapitalize + ')';
				break;

			default:
				msg = 'Se ha producido un error';

				break;
		}
		this.loginErrors.push(msg);
	}

	onSocialLogin(provider: 'google' | 'facebook') {
		localStorage.setItem('provider', provider);
		this.providerName = provider;
		console.log(`${this.environment.apiUrl}connect/${provider}`);
		window.location = `${this.environment.apiUrl}connect/${provider}`;
	}

	onToggleVisibility() {
		if (this.pwdVisibility) {
			this.passwordElement.nativeElement.type = 'password';
		} else {
			this.passwordElement.nativeElement.type = 'text';
		}
		this.pwdVisibility = !this.pwdVisibility;
	}
}
