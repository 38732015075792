<div
	class="subscription-container"
	[class.larger]="isLargerOn && plan.isLarger"
	[class.compact]="compactDesign"
	[class.limited-offer]="plan.discount_price || plan.offer_valid_until"
	[class.disabled]="plan.enabled == false"
>
	<div class="header">
		<span class="title">{{ plan.type }}</span>
		<span class="price"
			>{{
				(plan.discount_price || (plan.hasCoupon && plan.enabled) ? plan.discount_price : plan.price)
					| number: '1.0-2'
			}}€</span
		>
		<div class="limited-offer" *ngIf="!isCoupon && (plan.discount_price || plan.offer_valid_until)">
			<span class="limited-time" *ngIf="plan.offer_valid_until"
				>Oferta válida hasta el <br />
				{{ plan.offer_valid_until | date: "d 'de' MMMM" }}</span
			>
			<span class="original-price" *ngIf="plan.discount_price"
				>Precio original: {{ plan.price | number: '1.0-2' }}€</span
			>
		</div>
		<span class="base-price-discount" *ngIf="isCoupon && plan.enabled"
			>Precio original: {{ plan.price | number: '1.0-2' }}€</span
		>
	</div>
	<div class="discount-container" [class.coupon-discount]="isCoupon && plan.enabled">
		<span *ngIf="!plan.discount_percentage || plan.discount_percentage == 0; else discount">Ahorra 0%</span>
		<ng-template #discount
			><span>{{
				!isCoupon
					? 'Ahorra ' + plan.discount_percentage + '%'
					: plan.enabled
					? 'Cupón aplicado (-' + plan.discount_percentage + '%)'
					: 'Cupón no aplicable'
			}}</span>
		</ng-template>
	</div>
	<div class="description">
		<span>Disfruta de nuestros cursos a la carta</span>
	</div>
	<div class="buttons">
		<button
			[ngClass]="isLargerOn && plan.isLarger ? 'cta-primary' : 'btn-lighter'"
			(click)="onBtnAction($event)"
			[disabled]="plan.enabled == false"
		>
			{{ isGift ? 'Regalar' : 'Empieza ahora' }}
		</button>
		<span
			class="trial"
			*ngIf="
				!compactDesign && !hideTrialPeriod && plan.discount_percentage != 100 && plan.trial_period_days != 0
			"
		>
			Prueba {{ plan.trial_period_days }} días
		</span>
	</div>
</div>
