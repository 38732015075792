<div class="class-element">
	<div
		class="content-image"
		[routerLink]="[
			subcategory == true ? '/clases/subcategoria' : '/clases',
			class.slug,
			class.videos[0].slug
		]"
	>
		<app-image
			[srcImage]="class.cover_image ? class.cover_image.url : environment.placeholderImage"
		></app-image>
		<div class="bottom-info">
			<div class="duration">
				<img src="assets/images/icons/clock-white.svg" alt="clock-icon" />
				<span>{{ class.duration }}</span>
			</div>
			<div class="difficulty" *ngIf="!subcategory">
				<span>{{ class.difficulty }}</span>
			</div>
		</div>
	</div>
	<div class="content-text">
		<div class="class-title">
			<span
				[routerLink]="[
					subcategory == true ? '/clases/subcategoria' : '/clases',
					class.slug,
					class.videos[0].slug
				]"
				>{{ class.title }}</span
			>
			<img
				src="assets/images/icons/lock.svg"
				alt="lock-icon"
				*ngIf="!(class.is_free | membershipContentPipe)"
			/>
		</div>
		<!-- <div class="class-title subtitle">
            <span [routerLink]="['/clases', class.slug, class.videos[0].slug]">{{class.subtitle}}</span>
        </div> -->
		<div class="class-sub">
			<span class="subtitle">{{ class.subtitle }}</span>
			<span class="author">{{ author ? author.name + ' ' + author.surname : 'Dharma en Calma' }}</span>
		</div>
		<p class="class-description">{{ class.description }}</p>
	</div>
</div>
