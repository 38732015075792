import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'dharma-squaads-angular';
	subscription!: Subscription;
	testProd = true;
	hideCookiesDialog = true;
	constructor(private authService: AuthService, private router: Router) {}
	environment = environment;

	ngOnInit() {
		/*if (environment.production)
      this.watchTestProd();*/
		this.subscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd && this.authService.currentUser) this.authService.getMe();
			/*if (event instanceof ActivationEnd && environment.production) {
        this.handleTestProd(event);
      }*/
		});

		//Cookies dialog
		this.checkIfCookiesDialogIsNecessary();
	}

	handleTestProd(event: ActivationEnd | undefined) {
		const testProd = event && event.snapshot.queryParams['test-prod'];
		if (testProd) this.authService.setTestProd(testProd == 'true');
	}

	watchTestProd() {
		this.authService.testProdAsObservable().subscribe(testProd => {
			console.log(testProd);
			this.testProd = testProd;
		});
	}

	private checkIfCookiesDialogIsNecessary() {
		this.hideCookiesDialog = !!localStorage.getItem('cookies_accepted');
	}

	onCookiesAccepted() {
		this.checkIfCookiesDialogIsNecessary();
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
