import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { SubscriptionModel } from 'src/app/schemes/models/subscription.model';
import { environment } from 'src/environments/environment';
import { GiftModel } from './../schemes/models/gift.model';

export interface CreateGiftBody {
	priceId: string;
	paymentIntentId: string;
	receiverName: string;
	receiverEmail: string;
	senderName: string;
	message?: string;
}

@Injectable({
	providedIn: 'root',
})
export class GiftService {
	constructor(private http: HttpClient) {}

	createGift(body: CreateGiftBody): Promise<GiftModel> {
		return this.http
			.post<GiftModel>(`${environment.apiUrl}stripe/payment/gift`, { ...body })
			.pipe(take(1))
			.toPromise();
	}

	getGiftByCode(code: string): Promise<GiftModel> {
		return this.http
			.get<any>(`${environment.apiUrl}gift/code?code=` + code)
			.pipe(take(1))
			.toPromise();
	}

	async activateGift(body: { giftId: string }): Promise<SubscriptionModel> {
		return await this.http
			.post<SubscriptionModel>(`${environment.apiUrl}gift/activate`, body)
			.pipe(take(1))
			.toPromise();
	}
}
