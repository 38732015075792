import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserProvider } from '../providers/user.provider';
import { MembershipStatusEnum } from '../schemes/enums/membership-status.enum';
import { PromotionCodeModel } from '../schemes/models/promotion-code.model';
import { SubscriptionModel } from '../schemes/models/subscription.model';
import { environment } from './../../environments/environment';
import { UserModel } from './../schemes/models/user.model';
import { CouponService } from './coupon.service';
import { SubscriptionService } from './subscription.service';

const parseLocalStorageObj = (key: string) =>
	!!localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) || '') : null;

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	//User related
	private loginStatus = new BehaviorSubject<boolean>(this.isUserLoggedIn());
	private userLogged: any = new BehaviorSubject<UserModel>(parseLocalStorageObj('user_logged'));
	private authToken = new BehaviorSubject<any>(localStorage.getItem('auth_token'));
	private testProd = new BehaviorSubject<any>(localStorage.getItem('test_prod') == 'true');

	constructor(
		private http: HttpClient,
		private handler: HttpBackend,
		private router: Router,
		private userProvider: UserProvider,
		private subscriptionService: SubscriptionService,
		private couponService: CouponService
	) {}

	async signup(body: Object): Promise<any> {
		try {
			const response: any = this.http
				.post(environment.apiUrl + 'auth/local/register', body)
				.pipe(take(1))
				.toPromise();
			const mybody: any = body;
			this.setUnconfirmedUserAccountEmailAsSessionVariable(mybody.email);

			return response;
			// this.router.navigate(['unconfirmed']);
			// return response;

			//this.setSessionVariables(response.jwt, response.user);
			//this.router.navigate(['clases']);
		} catch (error) {
			throw error;
		}
	}

	async registerNewStripeClient(): Promise<any> {
		try {
			let userFullname = `${this.currentUser.name || 'Anónimo'} ${this.currentUser.surname || ''}`;
			let body: {
				name: string;
				email: string;
				description: string;
				stripeCustomerId?: string;
			} = {
				name: userFullname ? userFullname.trim() : '',
				email: this.currentUser.email ? this.currentUser.email : '',
				description: 'Cliente',
			};
			this.currentUser.stripeCustomerId && (body['stripeCustomerId'] = this.currentUser.stripeCustomerId);

			const result: { stripeCustomerId: string } = await this.http
				.post<{ stripeCustomerId: string }>(`${environment.apiUrl}stripe/new-client`, body)
				.pipe(take(1))
				.toPromise();
			localStorage.setItem(
				'user_logged',
				JSON.stringify({
					...this.currentUser,
					stripeCustomerId: result.stripeCustomerId,
				})
			);
			this.userLogged.next(parseLocalStorageObj('user_logged'));

			console.log('Creado con éxito...');
			return result.stripeCustomerId;
		} catch (error) {
			console.log(error);
		}
	}

	get isStripeUser() {
		return !!this.currentUser.stripeCustomerId;
	}

	async login(body: Object): Promise<any> {
		try {
			const response: any = await this.http
				.post(environment.apiUrl + 'auth/local', body)
				.pipe(take(1))
				.toPromise();
			this.setSessionVariables(response.jwt, response.user);
			await this.manageLoginRedirects();
			return true;
		} catch (error) {
			console.log('Error while logging in');
			if (error?.error?.message[0]?.messages[0]?.id == 'Auth.form.error.confirmed') {
				const email = Object.values(body)[0];
				this.setUnconfirmedUserAccountEmailAsSessionVariable(email);
			}
			throw error;
		}
	}

	async sendRecoveryEmailIfExists(email: string): Promise<any> {
		if (
			await this.http
				.get(environment.apiUrl + 'users/emailExists/' + email)
				.pipe(take(1))
				.toPromise()
		) {
			await this.http
				.post(environment.apiUrl + 'auth/forgot-password', { email: email })
				.pipe(take(1))
				.toPromise();
			return true;
		}
		return false;
	}

	async resendConfirmationEmail(email: string): Promise<any> {
		try {
			const response = await this.http
				.post(environment.apiUrl + 'auth/send-email-confirmation', { email: email })
				.pipe(take(1))
				.toPromise();
			return response;
		} catch (error) {
			console.log(error);
			throw error;
		}
	}

	hideTrialPeriod() {
		return !!(this.currentUser && this.currentUser.membershipStatus);
	}

	async resetPassword(password: string, passwordConfirmation: string, code: string): Promise<any> {
		return await this.http
			.post(environment.apiUrl + 'auth/reset-password', {
				code: code,
				password: password,
				passwordConfirmation: passwordConfirmation,
			})
			.pipe(take(1))
			.toPromise();
	}

	public logout(): void {
		this.clearLocalStorage();
		this.loginStatus.next(false);
		this.userLogged.next(null);
		this.router.navigate(['']);
	}

	private clearLocalStorage(): void {
		let userHasAcceptedCookies = !!localStorage.getItem('cookies_accepted');
		localStorage.clear();

		if (userHasAcceptedCookies) localStorage.setItem('cookies_accepted', 'true');
	}

	public async editProfile(body: Object): Promise<any> {
		try {
			await this.http
				.put(environment.apiUrl + 'users/me', body)
				.pipe(take(1))
				.toPromise();
			this.getMe();
			return true;
		} catch (error) {
			throw error;
		}
	}

	public async changePassword(body: Object): Promise<any> {
		try {
			const response: any = await this.http
				.post(environment.apiUrl + 'users/me/changePassword', body)
				.pipe(take(1))
				.toPromise();
			await this.getMe();
			return true;
		} catch (error) {
			throw error;
		}
	}

	public async updateAvatar(avatarFile: File): Promise<any> {
		let user = JSON.parse(localStorage.getItem('user_logged') || '{}');
		if (user) {
			const formData = new FormData();
			formData.append('files', avatarFile);
			formData.append('refId', user.id);
			formData.append('ref', 'user');
			formData.append('field', 'profileAvatar');
			formData.append('source', 'users-permissions');

			try {
				//Deleting previous avatar if exists
				const response1 = await this.deleteUserAvatar(user);
				console.log(response1);
				console.log('Se ha eliminado la imagen anterior...');

				//Uploading new image to user's profile
				await this.http
					.post(environment.apiUrl + 'upload', formData)
					.pipe(take(1))
					.toPromise();

				//Updating local storage data
				await this.getMe();
			} catch (error) {
				console.log(error);
				throw error;
			}
		}
	}

	public async destroyMe(): Promise<any> {
		try {
			console.log('Procediendo a la eliminación...');
			const user: any = await this.http
				.delete(environment.apiUrl + 'users/me')
				.pipe(take(1))
				.toPromise();
			if (user.id) {
				localStorage.removeItem('auth_token');
				localStorage.removeItem('user_logged');
				localStorage.removeItem('provider');
				this.loginStatus.next(false);
				this.userLogged.next(null);
			}
		} catch (error) {
			console.log(error);
			throw error;
		}
	}

	private translateMembershipStatus(status: string) {
		const translations: {
			[key: string]: string;
		} = {
			Activa: 'active',
			Vencida: 'past_due',
			No_pagada: 'unpaid',
			Cancelada: 'canceled',
			Incompleta: 'incomplete',
			Incompleta_y_expirada: 'incomplete_expired',
			Prueba: 'trialing',
			Terminada: 'ended',
		};
		return translations[status] ? (translations[status] as MembershipStatusEnum) : null;
	}

	public async getMe(): Promise<UserModel> {
		try {
			let user: UserModel = await this.userProvider.getMe();
			if (!user.reachedBy) this.router.navigate(['setup']); //If user account hasn't been setup yet.
			if (user.stripeSubscriptionId) {
				const subscription: SubscriptionModel | undefined =
					await this.subscriptionService.getUserSubscriptionDetails();
				user.membershipStatus = subscription ? subscription.status : MembershipStatusEnum.canceled;
			} else {
				user.membershipStatus = this.translateMembershipStatus(
					user.membershipStatus
				) as unknown as MembershipStatusEnum;
			}

			localStorage.setItem('user_logged', JSON.stringify(user));
			this.userLogged.next(parseLocalStorageObj('user_logged'));
			return user;
		} catch (error) {
			console.log('Error at getMe() function');
			// ACCOUNT UNCONFIRMED LOGGED IN
			if (error.error.statusCode == 401 && error.error.message == 'Your account email is not confirmed.') {
				this.logout();
			}
			throw error;
		}
	}

	setTestProd(value: boolean) {
		localStorage.setItem('test_prod', value.toString());
		this.testProd.next(value);
	}

	getTestProd() {
		return localStorage.getItem('test_prod');
	}

	testProdAsObservable() {
		return this.testProd.asObservable();
	}

	private setSessionVariables(key: string, user: any): void {
		this.loginStatus.next(true);
		localStorage.setItem('auth_token', key);
		localStorage.setItem('user_logged', JSON.stringify(user));
		this.authToken.next(localStorage.getItem('auth_token') || '');
		this.userLogged.next(parseLocalStorageObj('user_logged'));

		if (localStorage.getItem('unconfirmedUserEmail')) localStorage.removeItem('unconfirmedUserEmail');
	}

	private setUnconfirmedUserAccountEmailAsSessionVariable(userEmail: string): void {
		localStorage.setItem('unconfirmedUserEmail', userEmail);
	}

	public async resetUserAvatar() {
		let user = JSON.parse(localStorage.getItem('user_logged') || '{}');
		try {
			await this.deleteUserAvatar(user);
			//Updating local storage data
			await this.getMe();
		} catch (error) {
			throw error;
		}
	}

	private async deleteUserAvatar(user: any): Promise<any> {
		if (user.profileAvatar) {
			const fileId = user.profileAvatar.id;
			try {
				await this.http
					.delete(environment.apiUrl + 'upload/files/' + fileId)
					.pipe(take(1))
					.toPromise();
			} catch (error) {
				throw error;
			}
		}
	}

	public getUsername(name: String): String {
		return name.toLowerCase() + Date.now().toString();
	}

	public async refreshToken(token: String): Promise<any> {
		var http = new HttpClient(this.handler);
		const body = {
			token: token,
		};

		try {
			const response: any = await http
				.post(environment.apiUrl + 'users-permissions/refreshToken', body, { responseType: 'text' })
				.pipe(take(1))
				.toPromise();
			return response;
		} catch (error) {
			throw error;
		}
	}

	get userMembership() {
		return this.currentUser && this.currentUser.membershipStatus
			? this.currentUser.membershipStatus
			: undefined;
	}

	updateUser(user: UserModel) {
		localStorage.setItem(
			'user_logged',
			JSON.stringify({
				...this.currentUser,
				...user,
			})
		);
		this.userLogged.next(parseLocalStorageObj('user_logged'));
	}

	public isUserLoggedIn(): boolean {
		if (!!(localStorage.getItem('auth_token') && localStorage.getItem('user_logged'))) {
			return true;
		} else {
			return false;
		}
	}

	//GETTERS
	get isLoggedIn() {
		return this.loginStatus.asObservable();
	}

	get currentUserLogged() {
		return this.userLogged.asObservable();
	}

	get currentUser(): UserModel {
		return this.userLogged.value;
	}

	get currentAuthToken() {
		return this.authToken.asObservable();
	}

	subscriptionStatus() {
		return !!(
			this.currentUser.stripeCustomerId &&
			this.currentUser.stripeSubscriptionId &&
			['active', 'past_due', 'unpaid', 'trialing'].includes(this.currentUser.membershipStatus)
		);
	}

	async socialAuthCallback(accessToken: string, provider: 'google' | 'facebook') {
		const response: any = await this.http
			.get(`${environment.apiUrl}auth/${provider}/callback?access_token=${accessToken}`)
			.pipe(take(1))
			.toPromise();
		console.log({ socialCallback: response });
		this.setSessionVariables(response.jwt, response.user);
		this.router.navigate(['clases']);
	}

	/* There are some cases where the user needs to be redirected to alternative pages after login*/
	private async manageLoginRedirects() {
		const storedPromotionCode = localStorage.getItem('promotion_code');
		if (storedPromotionCode) {
			const promotionCode = (await this.couponService
				.getPromotionCode(storedPromotionCode)
				.catch(e => console.log(e))) as PromotionCodeModel;
			if (promotionCode.coupon.percent_off == 100) {
				this.router.navigate(['suscripciones']);
				return;
			}
		}

		//Redirects users to payment page if needed after login.
		if (localStorage.getItem('purchase_subscription_id')) {
			let subscriptonId = localStorage.getItem('purchase_subscription_id');
			localStorage.removeItem('purchase_subscription_id');
			this.router.navigate(['compra', 'suscripcion', subscriptonId]);
			return;
		}

		// Redirects to gift activation page.
		if (localStorage.getItem('gift_url_code')) {
			this.router.navigate(['canjear'], { queryParams: { code: localStorage.getItem('gift_url_code') } });
			localStorage.removeItem('gift_url_code');
			return;
		}

		// Redirects to gift details page (user wants to gift a subscription)
		if (localStorage.getItem('gift_details_plan_id')) {
			this.router.navigate(['tienda/regalo/detalles'], {
				queryParams: { plan: localStorage.getItem('gift_details_plan_id') },
			});
			localStorage.removeItem('gift_details_plan_id');
			return;
		}

		this.router.navigate(['clases']);
	}
}
