import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AlertEnum } from 'src/app/schemes/enums/alert.enum';

@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, AfterViewInit, OnChanges {
	@Input() heading?: string;
	@Input() message: string[] | undefined;
	@Input() type?: AlertEnum;
	@Input() showAlert?: boolean;
	@Output() closeAlert = new EventEmitter();

	imagePath?: string;
	modalLoaded!: Promise<boolean>;

	constructor() {}

	ngOnInit(): void {
		this.settingAlert();
	}
	ngOnChanges() {
		this.settingAlert();
	}

	ngAfterViewInit() {
		this.modalLoaded = Promise.resolve(true);
	}

	onCloseAlert() {
		this.closeAlert.emit();
	}

	private settingAlert(): void {
		if (this.type) {
			this.imagePath = 'assets/alerts/' + this.type + '.svg';

			switch (this.type) {
				case 'check':
				case 'modification':
				case 'password':
				case 'email':
					this.heading = 'Proceso completado con éxito';
					break;

				case 'error':
					this.heading = this.heading ? this.heading : 'Oops...';
					break;

				case 'pay':
					this.heading = 'Pago realizado con éxito';
					break;

				default:
					break;
			}
		}
	}
}
