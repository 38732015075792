<div *ngIf="showAlert && (modalLoaded | async)" class="background-modal">
	<div class="modal">
		<img class="modal-icon" [src]="imagePath" alt="alert-icon" />
		<div class="text-container">
			<h3 class="heading" *ngIf="heading">{{ heading }}</h3>
			<div class="details" *ngIf="message">
				<p *ngFor="let paragraph of message">{{ paragraph }}</p>
			</div>
		</div>
		<button class="large-accent-btn" (click)="onCloseAlert()">Aceptar</button>
	</div>
</div>
