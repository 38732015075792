import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Author } from 'src/app/schemes/models/author.model';
import { UserModel } from 'src/app/schemes/models/user.model';
import { environment } from 'src/environments/environment';
import { CourseModel } from '../../schemes/models/course.model';
declare var window: any;
@Component({
	selector: 'app-course-element',
	templateUrl: './course-element.component.html',
	styleUrls: ['./course-element.component.scss'],
})
export class CourseElementComponent implements OnInit {
	@Input() sourceImgPath = environment.apiUrl.substr(0, environment.apiUrl.length - 1);
	@Input() course!: CourseModel & { purchased: boolean };
	@Input() user!: UserModel;
	showMyCourse = false;
	environment = environment;
	@Input() purchased!: boolean;
	@Input() showPurchaseLabel = true;
	@Input() showRecentlyViewedLabel = false;
	author!: Author;
	constructor(public router: Router) {}

	ngOnInit(): void {
		if (this.course.author) this.author = this.course.author;
	}
}
