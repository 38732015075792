import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MembershipContentPipeModule } from 'src/app/shared/pipes/membership-content/membership-content.module';
import { MembershipContentPipe } from 'src/app/shared/pipes/membership-content/membership-content.pipe';
import { VideoPlayerComponent } from './video-player.component';

@NgModule({
	imports: [MembershipContentPipeModule, CommonModule, FormsModule, RouterModule.forChild([])],
	exports: [VideoPlayerComponent],
	declarations: [VideoPlayerComponent],
	providers: [MembershipContentPipe],
})
export class VideoPlayerModule {}
