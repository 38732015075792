<ng-container *ngIf="relatedContent">
	<div class="flex-container" *ngIf="relatedContent.classes.length > 0 || relatedContent.courses.length > 0">
		<ul class="recommended-content">
			<li *ngFor="let class of relatedContent.classes" (click)="redirection('clases/' + class.slug)">
				<app-image [srcImage]="class.cover_image ? class.cover_image.url : environment.placeholderImage">
				</app-image>
				<span class="title">{{ class.title }}</span
				><span class="description">Clase</span>
			</li>
			<li *ngFor="let course of relatedContent.courses" (click)="redirection('cursos/' + course.slug)">
				<app-image
					[srcImage]="course.cover_image ? course.cover_image.url : environment.placeholderImage"
					[style]="{ width: '57px', height: '40px' }"
				></app-image>
				<span class="title">{{ course.title }}</span
				><span class="description">Curso</span>
			</li>
		</ul>
	</div>
</ng-container>
