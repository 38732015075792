import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router } from '@angular/router';
import { UserModel } from 'src/app/schemes/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { PlanProvider } from './../providers/plan.provider';
import { MembershipStatusEnum } from './../schemes/enums/membership-status.enum';
import { GiftService } from './../services/gift.service';

@Injectable({
	providedIn: 'root',
})
export class CanActivateLoginSignupRoutesAuthGuard implements CanActivate {
	//Prevents the logged in user from accessing signup and login pages.

	constructor(private auth: AuthService, private router: Router) {}

	canActivate() {
		// If user is already logged in
		if (this.auth.isUserLoggedIn()) {
			this.router.navigate(['clases']);
			return false;
		}
		return true;
	}
}

@Injectable({
	providedIn: 'root',
})
export class CanActivateAuthGuard implements CanActivateChild, CanActivate {
	//Prevents an unregistered user from accesing page that requieres login
	constructor(private auth: AuthService, private router: Router) {}

	canActivateChild(route: ActivatedRouteSnapshot) {
		return this.canUserNavigate(route);
	}

	canActivate(route: ActivatedRouteSnapshot) {
		return this.canUserNavigate(route);
	}

	private canUserNavigate(route: ActivatedRouteSnapshot): boolean {
		if (this.auth.isUserLoggedIn()) {
			return true;
		}
		this.manageRedirects(route);
		this.router.navigate(['login']);
		return false;
	}

	private manageRedirects(route: ActivatedRouteSnapshot) {
		switch (route.routeConfig?.path) {
			case 'regalo/detalles':
				localStorage.setItem('gift_details_plan_id', route.queryParams['plan']);
				break;

			default:
				break;
		}
	}
}
@Injectable({
	providedIn: 'root',
})
export class CanActivateUnconfirmedAccountPage implements CanActivate {
	constructor(private auth: AuthService, private router: Router) {}

	canActivate() {
		if (localStorage.getItem('unconfirmedUserEmail')) {
			return true;
		} else {
			this.router.navigate(['/']);
			return false;
		}
	}
}
@Injectable({
	providedIn: 'root',
})
export class CanActivatePurchasePage implements CanActivateChild {
	constructor(private auth: AuthService, private router: Router) {}

	canActivateChild(route: ActivatedRouteSnapshot) {
		let user = this.auth.currentUser;

		//Subscription payment form guard.
		if (route.paramMap.get('product') == 'suscripcion') {
			//Trying to purchase a product as gift
			if (route.queryParamMap.get('gift')) {
				if (localStorage.getItem('giftData')) {
					if (localStorage.getItem('promotion_code')) localStorage.removeItem('promotion_code');
					return true;
				}
				this.router.navigate(['tienda']);
				return false;
			}
			if (user && (!user.membershipStatus || MembershipStatusEnum.canceled == user.membershipStatus)) {
				return true;
			}
			if (
				user &&
				(user.membershipStatus == MembershipStatusEnum.active ||
					user.membershipStatus == MembershipStatusEnum.past_due ||
					user.membershipStatus == MembershipStatusEnum.trialing)
			) {
				this.router.navigate(['cuenta', 'suscripcion']);
				return false;
			}
		}
		return true;
	}
}
@Injectable({
	providedIn: 'root',
})
export class CanActivateInitialSetupAccountPage implements CanActivate {
	userLogged!: UserModel;
	constructor(private auth: AuthService, private router: Router) {}

	canActivate() {
		if (this.auth.isUserLoggedIn()) {
			this.userLogged = this.auth.currentUser;
			if (this.userLogged.reachedBy) {
				this.router.navigate(['clases']);
				return false;
			}
			return true;
		}
		this.router.navigate(['/']);
		return false;
	}
}

@Injectable({
	providedIn: 'root',
})
export class CanActivateGiftSetupForm implements CanActivate {
	constructor(private planProvider: PlanProvider, private router: Router) {}

	async canActivate(route: ActivatedRouteSnapshot) {
		const planId = route.queryParamMap.get('plan');

		if (planId && !isNaN(+planId)) {
			//Does the plan really exists?
			try {
				await this.planProvider.getById(planId);
				return true;
			} catch (error) {
				this.router.navigate(['tienda/regalo']);
				return false;
			}
		}
		this.router.navigate(['tienda/regalo']);
		return false;
	}
}
@Injectable({
	providedIn: 'root',
})
export class CanActivateRedeemGiftPage implements CanActivate {
	constructor(private giftService: GiftService, private router: Router) {}

	async canActivate(route: ActivatedRouteSnapshot) {
		const giftURLAccess = route.queryParamMap.get('code');

		if (giftURLAccess) {
			try {
				const gift = await this.giftService.getGiftByCode(giftURLAccess);
				if (!gift.redeemed) {
					return true;
				}
			} catch (error) {
				this.router.navigate(['/']);
				return false;
			}
		}
		this.router.navigate(['/']);
		return false;
	}
}
