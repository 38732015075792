<div class="container">
	<div class="content">
		<div class="text-container">
			<h4>¡Gracias por tu confianza!</h4>
			<p>En Dharma en Calma usamos cookies con la intención de ofrecerte la mejor experiencia posible.</p>
		</div>
		<div class="btns-container">
			<button class="cta-primary" (click)="onAcceptCookies()">Aceptar</button>
			<a class="secondary-link" [routerLink]="['/legal/cookies']">Leer más</a>
		</div>
	</div>
</div>
