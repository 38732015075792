import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
	CanActivateAuthGuard,
	CanActivateInitialSetupAccountPage,
	CanActivateLoginSignupRoutesAuthGuard,
	CanActivatePurchasePage,
	CanActivateRedeemGiftPage,
	CanActivateUnconfirmedAccountPage,
} from './guards/auth.guard';
import { ConfirmedAccountComponent } from './pages/confirmed-account/confirmed-account.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RedeemGiftComponent } from './pages/redeem-gift/redeem-gift.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SetupAccountComponent } from './pages/signup/setup-account/setup-account.component';
import { SignupComponent } from './pages/signup/signup.component';
import { UnconfirmedAccountEmailComponent } from './pages/unconfirmed-account/unconfirmed-account.component';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
	},
	{
		path: 'clases',
		loadChildren: () => import('./pages/classes/classes-list.module').then(m => m.ClassesListModule),
	},
	{
		path: 'clases/subcategoria',
		loadChildren: () => import('./pages/classes/classes-list.module').then(m => m.ClassesListModule),
	},
	{
		path: 'cursos',
		loadChildren: () => import('./pages/courses/courses-list.module').then(m => m.CoursesListModule),
	},
	{
		path: 'mi-actividad',
		loadChildren: () => import('./pages/my-activity/my-activity.module').then(m => m.MyAccountModule),
	},
	{
		path: 'suscripciones',
		loadChildren: () =>
			import('./pages/subscriptions/subscriptions-list.module').then(m => m.SubscriptionsListModule),
	},
	{
		path: 'cuenta',
		loadChildren: () => import('./pages/my-account/my-account.module').then(m => m.MyAccountModule),
	},
	{
		path: 'compra',
		loadChildren: () => import('./pages/payment-form/payment-form.module').then(m => m.PaymentFormModule),
		canActivateChild: [CanActivateAuthGuard, CanActivatePurchasePage],
	},
	{
		path: 'legal',
		loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalModule),
	},
	{
		path: 'calendario',
		loadChildren: () => import('./pages/calendar/calendar.module').then(m => m.CalendarModule),
	},
	{
		path: 'tienda',
		loadChildren: () => import('./pages/store/store.module').then(m => m.StoreModule),
	},
	{ path: 'signup', component: SignupComponent, canActivate: [CanActivateLoginSignupRoutesAuthGuard] },
	{
		path: 'reset-password',
		component: ResetPasswordComponent,
		canActivate: [CanActivateLoginSignupRoutesAuthGuard],
	},
	{
		path: 'login',
		loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
	},
	{
		path: 'test-prod',
		loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
	},
	{
		path: 'canjear',
		component: RedeemGiftComponent,
		canActivate: [CanActivateRedeemGiftPage],
	},
	{
		path: 'promociones',
		redirectTo: 'suscripciones',
	},
	{ path: 'setup', component: SetupAccountComponent, canActivate: [CanActivateInitialSetupAccountPage] },
	{
		path: 'unconfirmed',
		component: UnconfirmedAccountEmailComponent,
		canActivate: [CanActivateUnconfirmedAccountPage],
	},
	{ path: 'confirmed', component: ConfirmedAccountComponent },
	{ path: '**', component: NotFoundComponent }, //Not found page
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabled',
			scrollPositionRestoration: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
