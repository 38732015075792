import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class HttpService {
	constructor(private http: HttpClient) {}

	get<T>(url: string, headers?: HttpHeaders, params?: HttpParams): Promise<T> {
		return this.http
			.get<T>(environment.apiUrl + url, { headers, params })
			.pipe(take(1))
			.toPromise();
	}

	post<T>(url: string, body: any, headers?: HttpHeaders, params?: HttpParams): Promise<T> {
		return this.http
			.post<T>(environment.apiUrl + url, body, { headers, params })
			.pipe(take(1))
			.toPromise();
	}

	put(url: string, body: any, headers?: HttpHeaders, params?: HttpParams): Promise<Object> {
		return this.http
			.put(environment.apiUrl + url, body, { headers, params })
			.pipe(take(1))
			.toPromise();
	}

	patch(url: string, body: any, headers?: HttpHeaders, params?: HttpParams): Promise<Object> {
		return this.http
			.patch(environment.apiUrl + url, body, { headers, params })
			.pipe(take(1))
			.toPromise();
	}

	delete(url: string, headers?: HttpHeaders, params?: HttpParams) {
		return this.http
			.delete(environment.apiUrl + url, { headers, params })
			.pipe(take(1))
			.toPromise();
	}
}
