import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { SearchBarComponent } from './../search-bar/search-bar.component';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
	// TODO any type ...
	loginStatus: boolean = false;
	currentUser!: any;
	userAvatarSrc!: string;

	openDropdown: boolean = false;
	openDropdownMobile: boolean = false;

	@ViewChild(SearchBarComponent) searchBarComponent!: SearchBarComponent;

	alternativeRoutesAdded = false;

	//Observables
	loginStatus$!: Observable<boolean>;
	currentUser$!: Observable<string>;

	public commonRoutes: { label: string; routerLink: string }[] = [
		{
			label: 'Clases',
			routerLink: 'clases',
		},
		{
			label: 'Cursos',
			routerLink: 'cursos',
		},
		{
			label: 'Calendario',
			routerLink: 'calendario',
		},
		{
			label: 'Tienda',
			routerLink: 'tienda',
		},
	];

	public loggedInRoutes: { label: string; routerLink: string }[] = [
		{
			label: 'Mi actividad',
			routerLink: 'mi-actividad',
		},
	];

	public notLoggedInRoutes: { label: string; routerLink: string }[] = [
		{
			label: 'Acceso',
			routerLink: 'login',
		},
		{
			label: 'Empieza ahora',
			routerLink: 'signup',
		},
	];

	constructor(
		private auth: AuthService,
		private router: Router,
		private deviceService: DeviceDetectorService
	) {}

	ngOnInit(): void {
		this.loginStatus$ = this.auth.isLoggedIn;
		this.loginStatus$.subscribe(status => {
			this.loginStatus = status;
		});

		this.currentUser$ = this.auth.currentUserLogged;
		this.currentUser$.subscribe((user: any) => {
			this.currentUser = user || undefined;
			if (user && user.profileAvatar) {
				this.userAvatarSrc = user.profileAvatar.formats.thumbnail.url
					? user.profileAvatar.formats.thumbnail.url
					: user.profileAvatar.url;
			} else {
				this.userAvatarSrc = 'assets/images/icons/user.svg';
			}

			this.manageCommonRoutes();
		});
	}

	onClickMenuItem($event: Object) {
		console.log($event);
	}

	logout(): void {
		this.openDropdown = false;
		this.openDropdownMobile = false;
		this.auth.logout();
		this.router.navigate(['']);
	}

	onClickGoTo(item: { label: string; routerLink: string }): void {
		switch (item.label) {
			case 'Empieza ahora':
				this.router.navigate([item.routerLink]);
				break;

			default:
				break;
		}
	}

	onClickDropdownElement(route: string): void {
		if (route.includes('cuenta')) {
			const isDesktopDevice = this.deviceService.isDesktop();
			if (!isDesktopDevice && this.deviceService.deviceType != 'unknown') route = '/cuenta/menu';
		}

		this.router.navigate([route]);
		this.openDropdown = false;
		this.openDropdownMobile = false;
	}

	private manageCommonRoutes(): void {
		/**
		 * Showing plans route.
		 * Cases to show:
		 * 1. Non-logged in user.
		 * 2. Logged in user has no subscription yet.
		 * 3. Logged in user has a not valid subscription or his subscription has been canceled.
		 */
		let planRoute = {
			label: 'Suscripciones',
			routerLink: 'suscripciones',
		};
		let notValidSubscriptionStatus = !!(
			!this.currentUser?.stripeSubscriptionId &&
			(this.currentUser?.membershipStatus == 'canceled' || !this.currentUser?.membershipStatus)
		);
		let routesAdded = this.commonRoutes.indexOf(planRoute) && this.alternativeRoutesAdded;
		if (!this.currentUser || notValidSubscriptionStatus) {
			if (!routesAdded) {
				this.commonRoutes.push(planRoute);
				this.alternativeRoutesAdded = true;
			}
		} else {
			if (routesAdded) {
				this.commonRoutes = this.commonRoutes.slice(0, this.commonRoutes.indexOf(planRoute));
				this.alternativeRoutesAdded = false;
			}
		}
	}

	toggleDropdown(): void {
		this.openDropdown = !this.openDropdown;
		this.openDropdownMobile = !this.openDropdownMobile;
	}

	onSearchBarVisibility(open: boolean, event?: Event) {
		event && event.stopPropagation();
		this.searchBarComponent.setShowSearchBar(open);
	}
}
