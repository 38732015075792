<div *ngIf="confirm.show" class="background-modal">
	<div class="modal">
		<img class="modal-icon" *ngIf="confirm.type" [src]="imagePath" alt="confirm-icon" />
		<div class="text-container">
			<div class="details" *ngIf="confirm.message">
				<h3 class="heading">¿Deseas continuar?</h3>
				<p *ngFor="let paragraph of confirm.message" [innerHTML]="paragraph"></p>
			</div>
		</div>
		<div class="btns-container">
			<button class="accept large-accent-btn" (click)="onAccept()">{{ confirm.accept }}</button>
			<button class="decline btn-lighter accent" (click)="onDecline()">{{ confirm.decline }}</button>
		</div>
	</div>
</div>
