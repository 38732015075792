import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ImageComponent } from './image.component';

@NgModule({
	imports: [CommonModule, FormsModule, RouterModule.forChild([])],
	exports: [ImageComponent],
	declarations: [ImageComponent],
})
export class ImageModule {}
