import { Component, Input, OnInit } from '@angular/core';
import { Author } from 'src/app/schemes/models/author.model';
import { environment } from '../../../environments/environment';
import { ClassModel } from './../../schemes/models/class.model';
@Component({
	selector: 'app-class-element',
	templateUrl: './class-element.component.html',
	styleUrls: ['./class-element.component.scss'],
})
export class ClassElementComponent implements OnInit {
	@Input() class!: ClassModel;
	@Input() subcategory = false;
	isFreeContent = false;
	environment = environment;
	author!: Author | undefined;
	constructor() {}

	async ngOnInit() {
		if (this.class.author) this.author = this.class.author;
	}
}
