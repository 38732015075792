import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
	onGoingPetitionsCount = 0;

	private readonly DISABLED_LOADER_URLS = ['/recentActivity', '/addvisit'] as const;

	constructor(public loaderService: LoaderService) {}
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let timeoutId!: any;

		const isDisabledURL = this.DISABLED_LOADER_URLS.some(url => req.url.includes(url));
		const showLoader = this.onGoingPetitionsCount === 0 && !isDisabledURL;

		if (showLoader) {
			timeoutId = setTimeout(() => {
				this.loaderService.startBasicLoader();
			}, 500);
		}

		!isDisabledURL && this.onGoingPetitionsCount++;
		return next.handle(req).pipe(
			finalize(() => {
				clearTimeout(timeoutId);
				setTimeout(() => {
					this.onGoingPetitionsCount--;
					if (this.onGoingPetitionsCount <= 0) {
						this.loaderService.stopBasicLoader();
					}
				}, 50);
			})
		);
	}
}
