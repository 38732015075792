import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalAlertStatusModel } from '../../schemes/models/global-alert-status.model';
import { GlobalAlertModel } from './../../schemes/models/global-alert.model';
import { GlobalAlertService } from './../../services/global-alert.service';

@Component({
	selector: 'app-global-alert',
	templateUrl: './global-alert.component.html',
	styleUrls: ['./global-alert.component.scss'],
})
export class GlobalAlertComponent implements OnInit {
	showAlert = false;
	globalAlerts$!: Observable<GlobalAlertModel[]>;
	globalAlerts: GlobalAlertModel[] = [];

	constructor(
		private router: Router,
		private globalAlertService: GlobalAlertService,
		private auth: AuthService
	) {}

	ngOnInit(): void {
		this.globalAlerts$ = this.globalAlertService.globalAlerts;
		this.globalAlerts$.subscribe(globalAlertsArray => {
			this.globalAlerts = [];
			globalAlertsArray.forEach((alert: GlobalAlertModel, index) => {
				//Notify user when is necessary
				if (!alert.status.solved && !alert.status.user_notified) {
					this.globalAlerts.push(this.getSubscriptionFailureMessage(alert.alert, alert.status));
				}
			});
		});

		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				this.globalAlerts.forEach((alert: GlobalAlertModel, index) => {
					if (alert.message?.btn_to && this.router.isActive(alert.message.btn_to, false)) {
						this.showAlert = false;
					} else {
						this.showAlert = alert.show !== undefined ? alert.show : true;
					}
				});
			}
		});
	}

	onRouteTo(alert: GlobalAlertModel): void {
		this.showAlert = false;
		this.router.navigate([alert.message?.btn_to]);
	}

	private getSubscriptionFailureMessage(type: string, status: GlobalAlertStatusModel): GlobalAlertModel {
		var alert: GlobalAlertModel = {
			alert: type,
			status: status,
			message: {
				title: '',
				subtitle: '',
				btn_text: '',
				btn_to: '',
			},
		};
		switch (type) {
			case 'subscription_failure':
				alert = {
					alert: type,
					status: status,
					message: {
						title: 'No hemos podido renovar tu suscripción...',
						subtitle: 'El método de pago seleccionado por defecto ha dejado de ser válido.',
						btn_text: 'Solucionar problema',
						btn_to: '/cuenta/tarjetas',
					},
					show: this.auth.userMembership == 'past_due' ? true : false,
				};
				return alert;

			default:
				alert = {
					alert: type,
					status: status,
					message: {
						title: 'Se ha producido un error desconocido',
						subtitle: 'Contacta con un administrador para más información.',
					},
				};
				return alert;
		}
	}
}
