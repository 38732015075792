import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-image',
	templateUrl: './image.component.html',
	styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {
	environment = environment;
	emptyImage = environment.placeholderImage;
	apiPath = environment.apiUrl.substr(0, environment.apiUrl.length - 1);
	@Input() srcImage!: string;
	@Input() style!: { [key: string]: string | number };

	constructor() {}

	ngOnInit(): void {}
}
