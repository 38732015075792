import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertEnum } from 'src/app/schemes/enums/alert.enum';
import { AuthService } from 'src/app/services/auth.service';
import { matchPassword } from 'src/app/validators/match-password.validator';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-login',
	templateUrl: './reset-password.component.html',
	styleUrls: ['../login/login.component.scss', './reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
	resetPasswordForm!: FormGroup;
	response: any;
	user!: any;
	submitted: boolean = false;
	resetPasswordErrors: Array<String> = [];
	environment = environment;

	//Password visibility
	pwdVisibility = {
		password: false,
		confirmPassword: false,
	};
	@ViewChild('password') passwordElement!: ElementRef;
	@ViewChild('confirmPassword') confirmPasswordElement!: ElementRef;

	alertSuccess = {
		message: ['Contraseña actualizada correctamente.'],
		type: AlertEnum['modification'],
		show: false,
		close: () => {
			this.alertSuccess.show = false;
			this.router.navigate(['login']);
		},
	};
	alertError = {
		message: ['El enlace no existe o ha caducado.'],
		type: AlertEnum['error'],
		show: false,
		close: () => {
			this.alertError.show = false;
			this.router.navigate(['login/forgotten-password']);
		},
	};

	constructor(
		private fb: FormBuilder,
		private auth: AuthService,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.createForm();
	}

	async ngOnInit(): Promise<void> {
		try {
			const queryParams = this.route.snapshot.queryParams;
			if (!queryParams.code) {
				this.router.navigate(['clases']);
			}
		} catch (e) {
			this.handleError(e);
		}
	}

	async onSubmit(): Promise<void> {
		this.submitted = true;
		try {
			if (this.resetPasswordForm.valid) {
				this.resetPasswordErrors.length = 0;
				const queryParams = this.route.snapshot.queryParams;
				await this.auth
					.resetPassword(
						this.resetPasswordForm.value.password,
						this.resetPasswordForm.value.passwordConfirmation,
						queryParams.code
					)
					.then(() => (this.alertSuccess.show = true));
			}
		} catch (e) {
			this.handleError(e);
		}
	}

	handleError(e: any) {
		console.log(e.error);
		let error: string = e?.error?.message[0]?.messages[0]?.id;
		this.decodeError(error);
	}

	private createForm(): void {
		this.resetPasswordForm = this.fb.group(
			{
				password: ['', [Validators.required, Validators.minLength(8)]],
				passwordConfirmation: ['', Validators.required],
			},
			{
				validator: matchPassword('password', 'passwordConfirmation'),
			}
		);
	}

	private decodeError(error: String) {
		console.log('error: ' + error);
		let msg = '';
		switch (error) {
			case 'Auth.form.error.code.provide':
				msg = 'El código de recuperación proporcionado no es válido';
				this.alertError.show = true;
				break;

			default:
				msg = 'Se ha producido un error';

				break;
		}
		this.resetPasswordErrors.push(msg);
	}

	onToggleVisibility(element: string) {
		switch (element) {
			case 'confirmPassword':
				if (this.pwdVisibility.confirmPassword) {
					this.confirmPasswordElement.nativeElement.type = 'password';
				} else {
					this.confirmPasswordElement.nativeElement.type = 'text';
				}
				this.pwdVisibility.confirmPassword = !this.pwdVisibility.confirmPassword;
				break;

			case 'password':
				if (this.pwdVisibility.password) {
					this.passwordElement.nativeElement.type = 'password';
				} else {
					this.passwordElement.nativeElement.type = 'text';
				}
				this.pwdVisibility.password = !this.pwdVisibility.password;
				break;

			default:
				break;
		}
	}
}
