<div class="video-player">
	<div class="video-container" [class.active-subscription]="isSelectedVideoAuthorized">
		<div class="subscription-required" *ngIf="!isSelectedVideoAuthorized">
			<div class="msg-wrapper" (click)="onRequestContent()">
				<span *ngIf="!user">Regístrate para acceder a este contenido</span>
				<span *ngIf="type == 'class' && user && (!user?.stripeSubscriptionId || !user?.stripeCustomerId)"
					>Hazte con una suscripción para acceder a este contenido</span
				>
				<span *ngIf="type == 'course'">Compra el curso para acceder al contenido</span>
			</div>
			<div class="msg-session" routerLink="/login">
				<span *ngIf="!user">o inicia sesión aquí</span>
			</div>
		</div>

		<div class="plyr__video-embed" id="player-{{ playerId }}">
			<iframe
				*ngIf="sourceVideo && isSelectedVideoAuthorized; else unauthorized"
				[src]="sanitizer.bypassSecurityTrustResourceUrl(videoUrl)"
				allowfullscreen
				allowtransparency
				allow="autoplay;fullscreen"
			></iframe>
		</div>
		<button
			class="skip-intro box-shadow-md"
			(click)="onSkipIntro()"
			*ngIf="
				isPlaying && sourceVideo.is_intro && ((!user && sourceVideo.is_free) || isSelectedVideoAuthorized)
			"
		>
			Saltar introducción
		</button>
		<ng-template #unauthorized class="plyr__video-embed">
			<img [src]="sourceImgPath" />
		</ng-template>
	</div>
</div>
