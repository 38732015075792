<div class="page-container">
	<header class="page-title">
		<h3>¿Olvidaste la contraseña?</h3>
		<p class="subtitle">Introduce tu email y te enviaremos un enlace de restablecimiento de contraseña.</p>
	</header>
	<div class="flex-column">
		<div class="form-section">
			<form [formGroup]="forgottenPasswordForm" (ngSubmit)="onSubmit()">
				<div
					class="input-group"
					[class.error-found]="
						forgottenPasswordForm.invalid && submitted && forgottenPasswordForm.controls.email?.errors
					"
				>
					<label for="email">Email</label>
					<input type="email" name="email" formControlName="email" placeholder="Escribe aquí tu email" />
					<div class="errors-container" *ngIf="forgottenPasswordForm.controls.email?.errors">
						<span *ngIf="forgottenPasswordForm.controls.email?.errors?.required && submitted"
							>Este campo es obligatorio</span
						>
						<span *ngIf="forgottenPasswordForm.controls.email?.errors?.email && submitted"
							>Este email no es válido</span
						>
					</div>
				</div>
				<button class="box-shadow-md large-accent-btn" type="submit">Enviar</button>
			</form>
			<app-alert
				[type]="alert.type"
				[message]="alert.message"
				[showAlert]="alert.show"
				(closeAlert)="alert.close()"
			></app-alert>
		</div>
	</div>
</div>
