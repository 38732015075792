import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmModel } from 'src/app/schemes/models/confirm.model';

@Component({
	selector: 'app-confirm',
	templateUrl: './confirm.component.html',
	styleUrls: ['../alert/alert.component.scss', 'confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
	@Input() confirm: ConfirmModel = {
		message: new Array<string>(),
		show: false,
	};
	@Input() containsHTMLContent = false;
	@Output() accept = new EventEmitter();
	@Output() decline = new EventEmitter();
	imagePath?: string;

	constructor() {}

	ngOnInit(): void {
		if (this.confirm.type) this.setImagePath();
		if (!this.confirm.accept) this.confirm.accept = 'Aceptar';
		if (!this.confirm.decline) this.confirm.decline = 'Cancelar';
	}

	setImagePath() {
		this.imagePath = 'assets/alerts/' + this.confirm.type + '.svg';
	}

	onAccept() {
		this.accept.emit();
	}
	onDecline() {
		this.decline.emit();
	}
}
