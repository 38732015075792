<footer>
	<div class="footer-container disable-user-select">
		<div class="copyright">
			<span>© {{ currentDate | date: 'YYYY' }} - Dharma en Calma. Todos los derechos reservados.</span>
		</div>
		<div class="policies">
			<a [routerLink]="['/legal/aviso-legal']">Aviso Legal</a>
			<a [routerLink]="['/legal/terminos']">Términos y Condiciones</a>
			<a [routerLink]="['/legal/privacidad']">Política de Privacidad</a>
			<a [routerLink]="['/legal/cookies']">Política de Cookies</a>
		</div>
		<div class="social-networks">
			<ul>
				<li>
					<a href="https://www.instagram.com/dharmaencalma/" target="_blank"
						><i class="fab fa-instagram"></i
					></a>
				</li>
				<li>
					<a href="https://www.facebook.com/DharmaEnCalma/" target="_blank"
						><i class="fab fa-facebook-f"></i
					></a>
				</li>
				<li>
					<a href="https://www.youtube.com/channel/UCCQDTQWb6GCPGxF6oV9cWyw" target="_blank"
						><i class="fab fa-youtube"></i
					></a>
				</li>
				<li>
					<a
						href="https://open.spotify.com/user/4n3trnr9iwatcrzd2u17c85lx?si=xY2i3nDrQzmu-R82a9N4OA"
						target="_blank"
						><i class="fab fa-spotify"></i
					></a>
				</li>
			</ul>
		</div>
	</div>
</footer>
