<div class="background-searchbar" [class.hidden]="!showSearchBar">
	<div class="searchbar-wrapper" (clickOutside)="showSearchBar && exitSearchBar()">
		<div class="searchbar">
			<img class="search-icon" src="assets/images/icons/magnifying-glass.svg" alt="search-icon" />
			<input type="text" placeholder="Busca dentro de Dharma en Calma" #searchInput />
			<img
				class="close-icon"
				src="assets/images/icons/close-icon.svg"
				alt="close-icon"
				(click)="searchInput.value == '' ? exitSearchBar() : onReset()"
			/>
		</div>
		<div class="searchbar-open" *ngIf="searchInput.value.length > 0">
			<div class="search-loader-container" *ngIf="isSearching">
				<mat-progress-spinner mode="indeterminate" color="primary" diameter="80" strokeWidth="2">
				</mat-progress-spinner>
				<span *ngIf="searchInput.value.length <= 2">Introduzca mínimo 3 letras</span>
				<span *ngIf="searchInput.value.length > 2">Buscando contenido...</span>
			</div>
			<div class="not-results" *ngIf="noResults && !isSearching">
				<span class="top">No se han encontrado resultados para la búsqueda</span>
				<span class="bottom">Pruebe de nuevo con otros términos</span>
			</div>
			<ul class="search-results" *ngIf="results.length > 0">
				<li
					*ngFor="let item of results; index as i"
					[routerLink]="[item.type == 'Clase' ? 'clases' : 'cursos', item.slug]"
					(mouseover)="onHoverEffect(i)"
					(mouseout)="onHoverEffect(i)"
					id="element{{ i }}"
					(click)="exitSearchBar()"
				>
					<img src="{{ item.image }}" alt="{{ item.slug + '_image' }}" />
					<div class="result-info">
						<span class="title">{{ item.title }}</span>
						<span class="content-type">{{ item.type }}</span>
					</div>
				</li>
			</ul>
		</div>
	</div>
</div>
