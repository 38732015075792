import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({
	providedIn: 'root',
})
export class JWTService {
	decodedToken!: { [key: string]: string };

	constructor() {}

	decodeToken(jwt: string) {
		this.decodedToken = jwt_decode(jwt);
		return this.decodeToken;
	}

	getExpiryTime() {
		return this.decodedToken ? this.decodedToken.exp : null;
	}

	isTokenExpired(): boolean {
		const expiryTime: any = this.getExpiryTime();
		if (expiryTime) {
			return 1000 * expiryTime - new Date().getTime() < 5000;
		} else {
			return false;
		}
	}

	isThisTokenExpired(token: string) {
		this.decodeToken(token);
		const expiryTime: any = this.getExpiryTime();
		if (expiryTime) {
			return 1000 * expiryTime - new Date().getTime() < 5000;
		} else {
			return false;
		}
	}
}
