import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalAlertStatusModel } from '../schemes/models/global-alert-status.model';
import { GlobalAlertModel } from './../schemes/models/global-alert.model';
import { AuthService } from './auth.service';
@Injectable({
	providedIn: 'root',
})
export class GlobalAlertService {
	private currentUser$;

	private globalAlertsArray = new BehaviorSubject<GlobalAlertModel[]>([]);
	private _globalAlertsArray: Observable<GlobalAlertModel[]> = this.globalAlertsArray;

	constructor(private auth: AuthService) {
		this.currentUser$ = this.auth.currentUserLogged;
		this.currentUser$.subscribe((user: any) => {
			if (user && user.additional_info?.global_alerts) {
				this.setGlobalAlertsArray(user.additional_info.global_alerts);
			} else {
				this.globalAlertsArray.next([]);
			}
		});
	}

	private setGlobalAlertsArray(globalAlerts: JSON) {
		Object.entries(globalAlerts).forEach(([key, value]) => {
			let currentValues: GlobalAlertModel[] = [];
			currentValues.push({ alert: key, status: value });
			this.globalAlertsArray.next(currentValues);
		});
	}

	get globalAlerts() {
		return this._globalAlertsArray;
	}

	public alertByTypeExists(type: string): boolean {
		const alerts = this.globalAlertsArray.value;
		var exists = false;

		alerts.forEach((element: GlobalAlertModel) => {
			if (element.alert == type) {
				exists = true;
			}
		});
		return exists;
	}

	public getAlertStatusByType(type: string): GlobalAlertStatusModel {
		const index = this.globalAlertsArray.value.findIndex(element => element.alert == type);
		return index > -1 ? this.globalAlertsArray.value[index].status : { solved: true, user_notified: true };
	}
}
