import { FormGroup } from '@angular/forms';

export function matchPassword(password: string, confirmPassword: string) {
	return (formGroup: FormGroup) => {
		const control = formGroup.controls[password];
		const matchingControl = formGroup.controls[confirmPassword];
		if (matchingControl.errors && !matchingControl.errors.matchPassword) {
			return;
		}
		if (control.value !== matchingControl.value) {
			matchingControl.setErrors({ matchPassword: true });
		} else {
			matchingControl.setErrors(null);
		}
	};
}

export function samePasswordAsOldPassword(oldPassword: string, newPassword: string) {
	return (formGroup: FormGroup) => {
		const control = formGroup.controls[oldPassword];
		const matchingControl = formGroup.controls[newPassword];
		if (matchingControl.errors && !matchingControl.errors.samePasswordAsOldPassword) {
			return;
		}
		if (control.value === matchingControl.value) {
			matchingControl.setErrors({ samePasswordAsOldPassword: true });
		} else {
			matchingControl.setErrors(null);
		}
	};
}
