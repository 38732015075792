import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MembershipContentPipeModule } from 'src/app/shared/pipes/membership-content/membership-content.module';
import { ImageModule } from '../image/image.module';
import { ClassElementComponent } from './class-element.component';

@NgModule({
	imports: [MembershipContentPipeModule, ImageModule, CommonModule, FormsModule, RouterModule.forChild([])],
	exports: [ClassElementComponent],
	declarations: [ClassElementComponent],
})
export class ClassElementModule {}
