import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CardBrandPipeModule } from 'src/app/shared/pipes/card-brand/card-brand.module';
import { CardPreviewComponent } from './card-preview.component';

@NgModule({
	imports: [CardBrandPipeModule, CommonModule, FormsModule, RouterModule.forChild([])],
	exports: [CardPreviewComponent],
	declarations: [CardPreviewComponent],
})
export class CardPreviewModule {}
