<h1 class="under-construction" *ngIf="!testProd && environment.production">
	<img
		src="../assets/images/Pagina en construccion.png"
		style="object-fit: contain; width: 100%; height: auto"
	/>
</h1>

<div [hidden]="!testProd && environment.production">
	<router-outlet></router-outlet>

	<ngx-ui-loader></ngx-ui-loader>
	<app-header></app-header>
	<div class="main-container">
		<main>
			<app-global-alert></app-global-alert>
			<router-outlet> </router-outlet>
			<app-cookies-dialog
				[hidden]="hideCookiesDialog"
				(cookiesAccepted)="onCookiesAccepted()"
			></app-cookies-dialog>
		</main>
		<app-footer></app-footer>
	</div>
</div>
