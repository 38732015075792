import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertEnum } from 'src/app/schemes/enums/alert.enum';
import { AuthService } from './../../../services/auth.service';

@Component({
	selector: 'app-fill-user-info',
	templateUrl: './setup-account.component.html',
	styleUrls: [
		'./setup-account.component.scss',
		'../signup.component.scss',
		'../../login/login.component.scss',
	],
})
export class SetupAccountComponent implements OnInit {
	setupAccountForm!: FormGroup;
	submitted = false;
	newsletterAccepted = false;

	alertSuccess = {
		message: ['Cuenta configurada correctamente.'],
		type: AlertEnum['check'],
		show: false,
		close: () => {
			this.alertSuccess.show = false;
			this.router.navigate(['clases']);
		},
	};
	alertError = {
		message: ['Se ha producido un error...'],
		type: AlertEnum['error'],
		show: false,
		close: () => {
			this.alertError.show = false;
		},
	};

	constructor(private fb: FormBuilder, private auth: AuthService, private router: Router) {}

	ngOnInit(): void {
		this.setupAccountForm = this.fb.group({
			name: ['', Validators.required],
			surname: ['', [Validators.required]],
			reach: ['', [Validators.required]],
		});
	}

	async onSubmit() {
		this.submitted = true;
		if (this.setupAccountForm.valid) {
			let body: Object = {
				name: this.setupAccountForm.value.name,
				surname: this.setupAccountForm.value.surname,
				reachedBy: this.setupAccountForm.value.reach,
				newsletter: this.newsletterAccepted,
			};
			console.log(body);

			try {
				const response = await this.auth.editProfile(body);
				if (response) {
					this.alertSuccess.show = true;
				}
			} catch (error) {
				this.alertError.show = true;
			}
		}
	}

	onCheck(event: Event) {
		this.newsletterAccepted = (event.target as HTMLInputElement).checked;
	}
}
