<div class="page-container with-illustration" *ngIf="encodedEmail">
	<header class="page-title">
		<h3 class="title">Confirma tu email para continuar</h3>
	</header>
	<div class="content">
		<img src="assets/images/illustrations/unconfirmed-email.svg" alt="unconfirmed-email" />
		<div class="information-container">
			<p>
				Te hemos enviado un correo <em class="light">({{ encodedEmail }})</em> con las instrucciones que debes
				seguir para confirmar tu cuenta y poder así disfrutar de las experiencias que te esperan dentro de
				Dharma en Calma.
			</p>
			<div class="resend-email">
				<p>Si no encuentras nuestro correo, siempre podemos volver a enviártelo.</p>
				<button class="cta-primary" (click)="onResendConfirmation()">Reenviar correo de confirmación</button>
			</div>
		</div>
	</div>
</div>
<app-alert
	[type]="alertSend.type"
	[message]="alertSend.message"
	[showAlert]="alertSend.show"
	(closeAlert)="alertSend.close()"
></app-alert>
<app-alert
	[type]="alertError.type"
	[message]="alertError.message"
	[showAlert]="alertError.show"
	(closeAlert)="alertError.close()"
></app-alert>
