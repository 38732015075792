<div class="page-container">
	<header class="page-title">
		<h3>Iniciar sesión</h3>
	</header>
	<div class="flex-column">
		<div class="form-section">
			<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
				<div
					class="input-group"
					[class.error-found]="
						(loginForm.invalid && submitted && loginForm.controls.email?.errors) || loginErrors.length > 0
					"
				>
					<label for="email">Email</label>
					<input type="email" name="email" formControlName="email" />
					<div class="errors-container" *ngIf="loginForm.controls.email?.errors || loginErrors.length > 0">
						<span *ngIf="loginForm.controls.email?.errors?.required && submitted"
							>Este campo es obligatorio</span
						>
						<span *ngIf="loginForm.controls.email?.errors?.email && submitted">Escriba un email válido </span>
						<span *ngIf="loginErrors.length > 0">{{ loginErrors[0] }} </span>
					</div>
				</div>
				<div
					class="input-group"
					[class.error-found]="
						(loginForm.invalid && submitted && loginForm.controls.password?.errors) || loginErrors.length > 0
					"
				>
					<label for="password">Contraseña</label>
					<div class="password-visibility-toggle">
						<input #password type="password" name="password" formControlName="password" />
						<i class="fas fa-eye" (click)="onToggleVisibility()" *ngIf="!pwdVisibility"></i>
						<i class="fas fa-eye-slash" (click)="onToggleVisibility()" *ngIf="pwdVisibility"></i>
					</div>
					<div class="errors-container" *ngIf="loginForm.controls.password?.errors">
						<span *ngIf="loginForm.controls.password?.errors?.required && submitted"
							>Este campo es obligatorio</span
						>
					</div>
				</div>
				<button class="submit-btn box-shadow-md" type="submit">Iniciar sesión</button>
			</form>
			<div class="forgotten-password">
				<a [routerLink]="['/login/forgotten-password']">¿Olvidaste la contraseña?</a>
			</div>
		</div>
		<div class="third-parties-login-buttons-container">
			<span>O entra con</span>
			<button class="box-shadow-lg google" (click)="onSocialLogin('google')">
				<img src="assets/images/icons/social/google.svg" alt="google-icon" />Google
			</button>
			<button class="box-shadow-lg fb" (click)="onSocialLogin('facebook')">
				<img src="assets/images/icons/social/facebook.svg" alt="facebook-icon" />Facebook
			</button>
		</div>

		<div class="additional-container">
			<span>¿No tienes cuenta? <a [routerLink]="['/signup']"> Regístrate</a></span>
		</div>
	</div>
</div>
