import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { ClassProvider } from './../../providers/class.provider';
import { CourseProvider } from './../../providers/course.provider';
import { ClassModel } from './../../schemes/models/class.model';
import { CourseModel } from './../../schemes/models/course.model';

interface ResultsInterface {
	title: string;
	type: 'Clase' | 'Curso';
	slug: string;
	image?: string;
}
@Component({
	selector: 'app-search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
	@Output() closeSearchBar = new EventEmitter();
	@ViewChild('searchInput', { static: true }) searchInput!: ElementRef;

	showSearchBar = false;
	openSearchResultsContainer = false;
	noResults = false;
	isSearching = false;

	apiResponse: { classes: ClassModel[]; courses: CourseModel[] } = { classes: [], courses: [] };
	results: ResultsInterface[] = [];

	constructor(private classProvider: ClassProvider, private courseProvider: CourseProvider) {}

	ngOnInit(): void {
		fromEvent(this.searchInput.nativeElement, 'keyup')
			.pipe(
				map((event: any) => {
					this.setVariables();
					return event.target.value;
				}),
				filter(res => res.trim().length > 2),
				debounceTime(1000)
			)
			.subscribe(async (text: string) => {
				await this.requestResults(text);
				this.manageResults();
			});
	}

	private async requestResults(text: string): Promise<void> {
		/**
		 * Setting Strapi query
		 * We're searching for something like the following example
		 * _where[_or][0][title_contains]=text&_where[_or][1][description_contains]=text
		 * https://strapi.io/documentation/developer-docs/latest/developer-resources/content-api/content-api.html#filters
		 */

		let params = new HttpParams();
		params = params.set('_where[_or][0][title_contains]', text);
		params = params.set('_where[_or][1][description_contains]', text);
		params = params.set('_where[_or][2][slug_contains]', text);

		const [classes, courses] = await Promise.all([
			this.classProvider.getAll(params),
			this.courseProvider.getAll(params),
		]);
		this.apiResponse.classes = classes;
		this.apiResponse.courses = courses;
	}

	private manageResults(): void {
		const classes = this.apiResponse.classes.map((classElement: ClassModel) => {
			return {
				title: classElement.title,
				type: 'Clase',
				slug: classElement.slug,
				image: classElement.cover_image ? classElement.cover_image.url : environment.placeholderImage,
			} as ResultsInterface;
		});

		const courses = this.apiResponse.courses.map((course: CourseModel) => {
			return {
				title: course.title,
				type: 'Curso',
				slug: course.slug,
				image: course.cover_image ? course.cover_image.url : environment.placeholderImage,
			} as ResultsInterface;
		});
		this.results = classes.concat(courses);
		this.isSearching = false;
		this.noResults = !!!this.results.length;
	}

	private setVariables() {
		this.isSearching = true;
		this.noResults = false;
		this.openSearchResultsContainer = true;
		this.results = [];
	}

	private resetVariables() {
		this.isSearching = false;
		this.noResults = true;
		this.openSearchResultsContainer = false;
		this.results = [];
		(this.searchInput.nativeElement as HTMLInputElement).value = '';
	}

	onReset() {
		this.resetVariables();
		setTimeout(() => this.searchInput.nativeElement.focus(), 0);
	}

	exitSearchBar() {
		this.resetVariables();
		this.closeSearchBar.emit();
	}

	setShowSearchBar(status: boolean) {
		this.showSearchBar = status;
		setTimeout(() => this.searchInput.nativeElement.focus(), 0);
	}

	onHoverEffect(index: number) {
		document.getElementById('element' + index)?.classList.toggle('hover-effect');
	}
}
