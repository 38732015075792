<div class="page-container">
	<header class="page-title">
		<h3>Política de privacidad</h3>
	</header>
	<div class="content-container">
		<section>
			<div class="content">
				<p>La Política de Privacidad forma parte de las Condiciones Generales que rigen la presente Web.</p>
			</div>
		</section>
		<section>
			<h4 class="title">¿Quién es el responsable del tratamiento de sus datos?</h4>
			<div class="content">
				<p>
					CLARA MARÍA PÉREZ RETA <br />
					CIF: 52833706S <br />
					Domicilio: c/ Doramas nº8, 35200, Telde. <br />
					Tfno: 629620054 <br />
					Mail: cperreta@gmail.com
				</p>
				<p>
					Puede dirigirse de cualquier forma para comunicarse con nosotros. Nos reservamos el derecho a
					modificar o adaptar la presente Política de Privacidad en cualquier momento. Le recomendamos revisar
					la misma, y si se ha registrado y accede a su cuenta o perfil, se le informará de las
					modificaciones.
				</p>
				<p>Si pertenece a alguno de los siguientes colectivos, consulte la información desplegable:</p>
			</div>
		</section>
		<section>
			<h4 class="title">1. CONTACTOS DE LA WEB O DEL CORREO ELECTRÓNICO</h4>
			<div class="content">
				<div class="subsection">
					<h4 class="title">¿Qué datos recopilamos a través de la Web?</h4>
					<div class="content">
						<p>
							Podemos tratar su IP, qué sistema operativo o navegador usa, e incluso la duración de su visita,
							de forma anónima.
						</p>
						<p>
							Si nos facilita datos en el formulario de contacto, se identificará para poder contactar con
							usted en caso de que sea necesario.
						</p>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Con qué finalidades vamos a tratar sus datos personales?</h4>
					<div class="content">
						<ul>
							<li>Contestar a sus consultas, solicitudes o peticiones.</li>
							<li>Gestionar el servicio solicitado, contestar su solicitud, o tramitar su petición.</li>
							<li>Información por medios electrónicos, que versen sobre su solicitud.</li>
							<li>
								Información comercial o de eventos por medios electrónicos, siempre que exista autorización
								expresa.
							</li>
							<li>
								Realizar análisis y mejoras en la Web, sobre nuestros productos y servicios. Mejorar nuestra
								estrategia comercial.
							</li>
						</ul>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Cuál es la legitimación para el tratamiento de sus datos?</h4>
					<div class="content">
						<p>
							La aceptación y consentimiento del interesado: En aquellos casos donde para realizar una
							solicitud sea necesario cumplimentar un formulario y hacer un "click" en el botón de enviar, la
							realización del mismo implicará necesariamente que ha sido informado y ha otorgado expresamente
							su consentimiento al contenido de la cláusula anexada a dicho formulario o aceptación de la
							política de privacidad.
						</p>
						<p>
							Todos nuestros formularios cuentan con el símbolo * en los datos obligatorios. Si no facilita
							esos campos, o no marca el checkbox de aceptación de la política de privacidad, no se permitirá
							el envío de la información. Normalmente tiene la siguiente fórmula: “□ He leído y acepto la
							Política de privacidad.”
						</p>
					</div>
				</div>
			</div>
		</section>
		<section>
			<h4 class="title">2. CONTACTOS DEL NEWSLETTER</h4>
			<div class="content">
				<div class="subsection">
					<h4 class="title">¿Qué datos recopilamos a través del newsletter?</h4>
					<div class="content">
						<p>
							En la web, se permite suscribirse al Newsletter, si nos facilita una dirección de correo
							electrónico, a la que se remitirá la misma.
						</p>
						<p>
							Almacenaremos únicamente su email en nuestra base de datos, y procederemos a enviarle correos
							periódicamente, hasta que solicite la baja, o dejemos de enviar correos.
						</p>
						<p>Siempre tendrá la opción de darse de baja, en cualquier comunicación.</p>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Con qué finalidades vamos a tratar sus datos personales?</h4>
					<div class="content">
						<ul>
							<li>Gestionar el servicio solicitado.</li>
							<li>Información por medios electrónicos, que versen sobre su solicitud.</li>
							<li>
								Información comercial o de eventos por medios electrónicos, siempre que exista autorización
								expresa.
							</li>
							<li>
								Realizar análisis y mejoras en el envío de mailing, para mejorar nuestra estrategia comercial.
							</li>
						</ul>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Cuál es la legitimación para el tratamiento de sus datos?</h4>
					<div class="content">
						<p>
							La aceptación y consentimiento del interesado: En aquellos casos donde se suscriba será
							necesario aceptar un checkbox y clicar en el botón de enviar. Ello implicará necesariamente que
							ha sido informado y ha otorgado expresamente su consentimiento a la recepción del newsletter.
						</p>
						<p>
							Si no marca el checkbox de aceptación de la política de privacidad, no se permitirá el envío de
							la información. Normalmente tiene la siguiente fórmula: “&#9634; He leído y acepto la Política
							de privacidad.”
						</p>
					</div>
				</div>
			</div>
		</section>
		<section>
			<h4 class="title">3. CLIENTES</h4>
			<div class="content">
				<div class="subsection">
					<h4 class="title">¿Con qué finalidades vamos a tratar sus datos personales?</h4>
					<div class="content">
						<ul>
							<li>
								Elaboración del presupuesto y seguimiento del mismo mediante comunicaciones entre ambas
								partes.
							</li>
							<li>Información por medios electrónicos, que versen sobre su solicitud.</li>
							<li>
								Información comercial o de eventos por medios electrónicos, siempre que exista autorización
								expresa.
							</li>
							<li>
								Gestionar los servicios administrativos, de comunicaciones y de logística realizados por el
								Responsable.
							</li>
							<li>Facturación y declaración de los impuestos oportunos.</li>
							<li>Realizar las transacciones que correspondan.</li>
							<li>Gestiones de control y recobro.</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
		<section>
			<h4 class="title">4. ENCUESTAS DE CALIDAD</h4>
			<div class="content">
				<div class="subsection">
					<h4 class="title">¿Con qué finalidades vamos a tratar sus datos personales?</h4>
					<div class="content">
						<ul>
							<li>Valorar el grado de calidad en el servicio dispensado</li>
							<li>Mejorar los servicios ofrecidos, en virtud del cumplimiento de la ISO</li>
						</ul>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Cuál es la legitimación para el tratamiento de sus datos?</h4>
					<div class="content">
						<p>La base legal es el consentimiento expreso del encuestado.</p>
					</div>
				</div>
			</div>
		</section>
		<section>
			<h4 class="title">5. PROVEEDORES</h4>
			<div class="content">
				<div class="subsection">
					<h4 class="title">¿Con qué finalidades vamos a tratar sus datos personales?</h4>
					<div class="content">
						<ul>
							<li>Información por medios electrónicos, que versen sobre su solicitud.</li>
							<li>
								Información comercial o de eventos por medios electrónicos, siempre que exista autorización
								expresa.
							</li>
							<li>
								Gestionar los servicios administrativos, de comunicaciones y de logística realizados por el
								Responsable.
							</li>
							<li>Facturación.</li>
							<li>Realizar las transacciones que correspondan.</li>
							<li>Facturación y declaración de los impuestos oportunos.</li>
							<li>Gestiones de control y recobro.</li>
						</ul>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Cuál es la legitimación para el tratamiento de sus datos?</h4>
					<div class="content">
						<p>
							La base legal es la aceptación de una relación contractual, o en su defecto su consentimiento al
							contactar con nosotros y ofrecernos sus productos por alguna vía.
						</p>
					</div>
				</div>
			</div>
		</section>
		<section>
			<h4 class="title">6. CONTACTOS REDES SOCIALES</h4>
			<div class="content">
				<div class="subsection">
					<h4 class="title">¿Con qué finalidades vamos a tratar sus datos personales?</h4>
					<div class="content">
						<ul>
							<li>Contestar a sus consultas, solicitudes o peticiones.</li>
							<li>Gestionar el servicio solicitado, contestar su solicitud, o tramitar su petición.</li>
							<li>Relacionarnos con usted y crear una comunidad de seguidores.</li>
						</ul>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Cuál es la legitimación para el tratamiento de sus datos?</h4>
					<div class="content">
						<p>
							La aceptación de una relación contractual en el entorno de la red social que corresponda, y
							conforme a sus políticas de Privacidad:
						</p>
						<ul>
							<li>
								<span class="title">Facebook</span>
								<p>
									<a href="http://www.facebook.com/policy.php?ref=pf">
										http://www.facebook.com/policy.php?ref=pf
									</a>
								</p>
							</li>
							<li>
								<span class="title">Instagram</span>
								<p>
									<a href="https://help.instagram.com/155833707900388">
										https://help.instagram.com/155833707900388
									</a>
								</p>
							</li>
							<li>
								<span class="title">Twitter</span>
								<p>
									<a href="http://twitter.com/privacy"> http://twitter.com/privacy </a>
								</p>
							</li>
							<li>
								<span class="title">Linkedin</span>
								<p>
									<a href="http://www.linkedin.com/legal/privacy-policy?trk=hb_ft_priv">
										http://www.linkedin.com/legal/privacy-policy?trk=hb_ft_priv
									</a>
								</p>
							</li>
							<li>
								<span class="title">Pinterest</span>
								<p>
									<a href="https://about.pinterest.com/es/privacy-policy">
										https://about.pinterest.com/es/privacy-policy
									</a>
								</p>
							</li>
							<li>
								<span class="title">Google (Google + y YouTube</span>
								<p>
									<a href="http://www.google.com/intl/es/policies/privacy/">
										http://www.google.com/intl/es/policies/privacy/
									</a>
								</p>
							</li>
						</ul>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Durante cuánto tiempo vamos a mantener los datos personales?</h4>
					<div class="content">
						<p>
							Sólo podemos consultar o dar de baja sus datos de forma restringida al tener un perfil
							específico. Los trataremos tanto tiempo como usted nos lo permita siguiéndonos, siendo amigos o
							dándole a “me gusta”, “seguir” o botones similares. Cualquier rectificación de sus datos o
							restricción de información o de publicaciones debe realizarla a través de la configuración de su
							perfil o usuario en la propia red social.
						</p>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Incluimos datos personales de terceras personas?</h4>
					<div class="content">
						<p>
							No, como norma general sólo tratamos los datos que nos facilitan los titulares. Si nos aporta
							datos de terceros deberá, con carácter previo, informar y solicitar su consentimiento a dichas
							personas, o de lo contrario nos exime de cualquier responsabilidad por el incumplimiento de este
							requisito.
						</p>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Incluimos datos personales de terceras personas?</h4>
					<div class="content">
						<p>
							No, como norma general sólo tratamos los datos que nos facilitan los titulares. Si nos aporta
							datos de terceros deberá, con carácter previo, informar y solicitar su consentimiento a dichas
							personas, o de lo contrario nos exime de cualquier responsabilidad por el incumplimiento de este
							requisito.
						</p>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Y datos de menores?</h4>
					<div class="content">
						<p>
							No tratamos datos de menores de 14 años sin consentimiento del padre, madre o tutor legal. Por
							tanto, absténgase de facilitarlos si no tiene esa edad o, en su caso, de facilitar datos de
							terceros que no tengan la citada edad. CLARA MARÍA PÉREZ RETA se exime de cualquier
							responsabilidad por el incumplimiento de esta previsión.
						</p>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Realizaremos comunicaciones por medios electrónicos?</h4>
					<div class="content">
						<p>
							Solo se realizarán para gestionar su solicitud, si es uno de los medios de contacto que nos ha
							facilitado.
						</p>
						<p>
							Si realizamos comunicaciones comerciales habrán sido previa y expresamente autorizadas por
							usted.
						</p>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Qué medidas de seguridad aplicamos?</h4>
					<div class="content">
						<p>
							Puede estar tranquilo: Hemos adoptado un nivel óptimo de protección de los Datos Personales que
							manejamos, y hemos instalado todos los medios y medidas técnicas a nuestra disposición según el
							estado de la tecnología para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo
							de los Datos Personales.
						</p>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿A qué destinatarios se comunicarán sus datos?</h4>
					<div class="content">
						<p>
							Sus datos no se cederán a terceros, salvo obligación legal. En concreto se comunicarán a la
							Agencia Estatal de la Administración Tributaria y a bancos y entidades financieras para el cobro
							del servicio prestado o producto adquirido, como a los encargados del tratamiento necesarios
							para la ejecución del acuerdo.
						</p>
						<p>
							En caso de compra o pago, si elige alguna aplicación, web, plataforma, tarjeta bancaria, o algún
							otro servicio online, sus datos se cederán a esa plataforma o se tratarán en su entorno, siempre
							con la máxima seguridad.
						</p>
						<p>
							Cuando se lo ordenemos, tendrán acceso a nuestra web la empresa de desarrollo y mantenimiento
							web, o la de hosting. Las mismas tendrán firmado un contrato de prestación de servicios que les
							obliga a mantener el mismo nivel de privacidad que nosotros.
						</p>
						<p>
							Cualquier transferencia internacional de datos al usar aplicaciones americanas, estará adherida
							al convenio Privacy Shield, que garantiza que las empresas de software americano cumplen las
							políticas de protección de datos europeas en materia de privacidad.
						</p>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Qué derechos tiene?</h4>
					<div class="content">
						<ul>
							<li>A saber si estamos tratando sus datos o no.</li>
							<li>A acceder a sus datos personales.</li>
							<li>A solicitar la rectificación de sus datos si son inexactos.</li>
							<li>
								A solicitar la supresión de sus datos si ya no son necesarios para los fines para los que
								fueron recogidos o si nos retira el consentimiento otorgado.
							</li>
							<li>
								A solicitar la limitación del tratamiento de sus datos, en algunos supuestos, en cuyo caso
								solo los conservaremos de acuerdo con la normativa vigente.
							</li>
							<li>
								A portar sus datos, que le serán facilitados en un formato estructurado, de uso común o
								lectura mecánica. Si lo prefiere, se los podemos enviar al nuevo responsable que nos designe.
								Sólo es válido en determinados supuestos.
							</li>
							<li>
								A presentar una reclamación ante la Agencia Española de Protección de Datos o autoridad de
								control competente, si cree que no le hemos atendido correctamente.
							</li>
							<li>
								A revocar el consentimiento para cualquier tratamiento para el que haya consentido, en
								cualquier momento.
							</li>
						</ul>
						<p>Si modifica algún dato, le agradecemos que nos lo comunique para mantenerlos actualizados.</p>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Quiere un formulario para el ejercicio de Derechos?</h4>
					<div class="content">
						<p>
							Tenemos formularios para el ejercicio de sus derechos, pídanoslos por email, o si lo prefiere,
							puede usar los elaborados por la Agencia Española de Protección de Datos o terceros.
						</p>
						<p>
							Estos formularios deben ir firmados electrónicamente o ser acompañados de fotocopia del DNI. Si
							le representa alguien, debe adjuntarnos copia de su DNI, o también firmado con su firma
							electrónica.
						</p>
						<p>
							Los formularios pueden ser presentados presencialmente, enviados por carta o por mail en la
							dirección del Responsable al inicio de este texto.
						</p>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Cuánto tardamos en contestar al Ejercicio de Derechos?</h4>
					<div class="content">
						<p>
							Depende del derecho, pero como máximo en un mes desde su solicitud, y dos meses si el tema es
							muy complejo. Le notificaremos si necesitamos más tiempo.
						</p>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Tratamos cookies?</h4>
					<div class="content">
						<p>
							Si usamos otro tipo de cookies que no sean las necesarias, podrá consultar la política de
							cookies en el enlace correspondiente desde el inicio de nuestra web.
						</p>
					</div>
				</div>
				<div class="subsection">
					<h4 class="title">¿Durante cuánto tiempo vamos a mantener sus datos personales?</h4>
					<div class="content">
						<p>
							Los datos personales serán mantenidos mientras siga vinculado con nosotros. Una vez se
							desvincule, los datos personales tratados en cada finalidad se mantendrán durante los plazos
							legalmente previstos, incluido el plazo en el que un juez o tribunal los pueda requerir
							atendiendo al plazo de prescripción de acciones judiciales.
						</p>
						<p>
							Los datos tratados se mantendrán en tanto no expiren los plazos legales aludidos anteriormente,
							si hubiera obligación legal de mantenimiento, o de no existir ese plazo legal, hasta que el
							interesado solicite su supresión o revoque el consentimiento otorgado.
						</p>
						<p>
							Mantendremos toda la información y comunicaciones relativas a su compra o a la prestación de
							nuestro servicio, mientras duren las garantías de los productos o servicios, para atender
							posibles reclamaciones.
						</p>
					</div>
				</div>
			</div>
		</section>
	</div>
</div>
