import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SeoModel, SeoPagesModel } from '../schemes/models/seo.model';
import { HttpService } from './http.service';

@Injectable({
	providedIn: 'root',
})
export class SeoService {
	seoPagesMetadata!: SeoPagesModel;

	constructor(private meta: Meta, private title: Title, private http: HttpService) {}

	addSeoMetadata(title: string, seo: SeoModel, page: string) {
		const meta: MetaDefinition[] = [
			{
				name: 'og:title',
				content: (seo && seo.title ? seo.title : page.toUpperCase()) + ' - ' + environment.title,
			},
			{ name: 'keywords', content: seo ? seo.keywords : '' },
			{ name: 'description', content: seo ? seo.description : '' },
			{ name: 'og:description', content: seo.description },
			{ name: 'og:site_name', content: environment.site_name },
			{
				name: 'og:image',
				content:
					seo && seo.image
						? environment.production
							? seo.image.url
							: environment.apiUrl + seo.image.url.slice(1)
						: environment.logo,
			},
			{ name: 'og:url', content: seo.url ? seo.url : environment.url + '/' + page.toLowerCase() },
		];
		meta.forEach(m => this.meta.updateTag(m));
		//this.meta.addTags(meta, true);
		this.title.setTitle(title);
	}

	async getSeo() {
		if (this.seoPagesMetadata) return this.seoPagesMetadata;
		this.seoPagesMetadata = await this.http.get('seo', undefined);
		return this.seoPagesMetadata;
	}
}
