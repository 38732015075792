import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertComponent } from './alert.component';

@NgModule({
	imports: [CommonModule, FormsModule, RouterModule.forChild([])],
	exports: [AlertComponent],
	declarations: [AlertComponent],
})
export class AlertModule {}
