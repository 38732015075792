<div
	class="card"
	[class.invalid]="!card.isValid"
	[class.radiobuttons]="hasRadioButtons"
	(click)="hasRadioButtons && onSelectCard()"
>
	<input
		type="radio"
		[(ngModel)]="defaultCardId"
		name="card"
		[value]="card.id"
		(ngModelChange)="onSelectCard()"
		*ngIf="hasRadioButtons"
	/>
	<i class="{{ card.brand.toLowerCase() }}"></i>
	<div class="card-info">
		<div class="top-line">
			<p class="card-number">{{ card.brand | titlecase }} ···· {{ card.last4 }}</p>
		</div>
		<p class="card-exp" *ngIf="!stripeCardExpirationError; else expiredCard">
			Válida hasta {{ card.expiration_date | date: 'LLL y' }}
		</p>
		<ng-template #expiredCard>
			<p class="card-exp expired">Caducada desde {{ card.expiration_date | date: 'LLL y' }}</p>
		</ng-template>
	</div>
	<div class="buttons" *ngIf="!hasRadioButtons">
		<button *ngIf="defaultCardId != ''" class="cta-primary btn-lighter" (click)="onSetDefaultCard()">
			Hacer predeterminada
		</button>
		<button class="btn-lighter delete" (click)="onDeleteCard()">Eliminar</button>
	</div>
	<div class="buttons" *ngIf="defaultCardId == '' && !hasRadioButtons && subscriptionFailure">
		<button class="accent btn-lighter retry" (click)="onRetryPayment()">Reintentar pago</button>
	</div>
</div>
