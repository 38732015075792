import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeES from '@angular/common/locales/es';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER } from 'ngx-ui-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertModule } from './components/alert/alert.module';
import { ConfirmModule } from './components/confirm/confirm.module';
import { CookiesDialogComponent } from './components/cookies-dialog/cookies-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { GlobalAlertComponent } from './components/global-alert/global-alert.component';
import { HeaderComponent } from './components/header/header.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ConfirmedAccountComponent } from './pages/confirmed-account/confirmed-account.component';
import { LegalComponent } from './pages/legal/legal.component';
import { PrivacyPolicyComponent } from './pages/legal/privacy-policy/privacy-policy.component';
import { LoginModule } from './pages/login/login.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RedeemGiftComponent } from './pages/redeem-gift/redeem-gift.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SetupAccountComponent } from './pages/signup/setup-account/setup-account.component';
import { SignupComponent } from './pages/signup/signup.component';
import { UnconfirmedAccountEmailComponent } from './pages/unconfirmed-account/unconfirmed-account.component';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { RouterService } from './services/router-service.service';
import { LoaderInterceptor } from './shared/loader.interceptor';

registerLocaleData(localeES);

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
	bgsColor: '#7180a2',
	bgsOpacity: 1,
	bgsPosition: POSITION.centerCenter,
	bgsSize: 40,
	bgsType: SPINNER.threeStrings,
	fgsColor: '#7180a2',
	fgsPosition: POSITION.centerCenter,
	pbColor: '#7180a2',
	pbDirection: PB_DIRECTION.leftToRight,
	pbThickness: 5,
};

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		NavbarComponent,
		FooterComponent,
		SignupComponent,
		ResetPasswordComponent,
		UnconfirmedAccountEmailComponent,
		PrivacyPolicyComponent,
		LegalComponent,
		NotFoundComponent,
		ConfirmedAccountComponent,
		GlobalAlertComponent,
		SetupAccountComponent,
		ClickOutsideDirective,
		RedeemGiftComponent,
		SearchBarComponent,
		CookiesDialogComponent,
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		AppRoutingModule,
		HttpClientModule,
		ReactiveFormsModule,
		LoginModule,
		AlertModule,
		ConfirmModule,
		NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
		MatProgressSpinnerModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
		{ provide: LOCALE_ID, useValue: 'es' },
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(private routerService: RouterService) {}
}
