import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { PlanModel } from './../schemes/models/plan.model';
import { BaseProvider } from './base.provider';
@Injectable({
	providedIn: 'root',
})
export class PlanProvider extends BaseProvider<PlanModel> {
	constructor(http: HttpService) {
		super('plans', http);
	}
}
