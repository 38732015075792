import { Pipe, PipeTransform } from '@angular/core';
import { MembershipStatusEnum } from 'src/app/schemes/enums/membership-status.enum';
import { CourseModel } from 'src/app/schemes/models/course.model';
import { AuthService } from 'src/app/services/auth.service';

@Pipe({ name: 'coursePricePipe' })
export class CoursePricePipe implements PipeTransform {
	constructor(private authService: AuthService) {}

	transform(course: CourseModel): number {
		const user = this.authService.currentUser;
		const activeSubscription =
			user && [MembershipStatusEnum.active, MembershipStatusEnum.trialing].includes(user.membershipStatus);
		return activeSubscription ? course.subscriber_price : course.default_price;
	}
}
